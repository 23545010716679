import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ACTION_MODES, NAVIGATE_TOASTER_TIMEOUT, TAB_KEYS } from '@common/Constants';
import formatBytes from '@common/formatters/formatBytes';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { TAB_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { closeConfirmDialog } from '@components/ConfirmDialog/confirmDialogSlice';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { TABLE_OPTIONS } from '@config/network';
import { getByPathAndParams, deleteByPath, postByPathAndData } from '@services/BaseApi';

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  filter: {
    search: '',
    match: '',
    searchValue: '',
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0]
  },
  totalElements: 0,
  selectionModel: []
};

export const ATTACHMENTS_SLICE = 'attachmentsSlice';

export const getAttachments = createAsyncThunk(
  `${ATTACHMENTS_SLICE}/fetchAll`,
  (
    { entityId, entityType, filter: { page, size, match, search, searchValue } },
    { rejectWithValue }
  ) => {
    return getByPathAndParams({
      path: TAB_PATHS.ATTACHMENTS.GET,
      pathVariables: { entityId, entityType },
      params: {
        page,
        size,
        match,
        search,
        searchValue
      }
    })
      .then(({ data }) => {
        const formatted = data?.content?.map((item) => {
          if (item.size) {
            item.size = formatBytes(item.size);
            return item;
          }
          return item;
        });
        return { ...data, content: formatted };
      })
      .catch((error) => {
        return rejectWithValue(error.response);
      });
  },
  {
    condition: (entityId) => Boolean(entityId),
    dispatchConditionRejection: true
  }
);

export const removeAttachments = createAsyncThunk(
  `${ATTACHMENTS_SLICE}/removeAll`,
  ({ entityId, entityType, id, setDetails }, { rejectWithValue, dispatch }) => {
    return deleteByPath({
      path: TAB_PATHS.ATTACHMENTS.DELETE,
      pathVariables: { entityId, entityType, id }
    })
      .then(({ data: { count } }) => {
        dispatch(closeConfirmDialog());
        dispatch(setDetails({ counts: { [TAB_KEYS.ATTACHMENT]: count } }));
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.ATTACHMENTS, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return count;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.ATTACHMENTS, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
        return rejectWithValue(error.response);
      });
  },
  {
    condition: (entityId) => Boolean(entityId),
    dispatchConditionRejection: true
  }
);

export const uploadAttachment = createAsyncThunk(
  `${ATTACHMENTS_SLICE}/uploadOne`,
  ({ entityId, entityType, file, setDetails }, { rejectWithValue, dispatch }) => {
    const formData = new FormData();
    formData.append('objectId', entityId);
    formData.append('objectName', entityType);
    formData.append('file', file);
    formData.append('createdBy', 'Demo User');

    return postByPathAndData({
      path: TAB_PATHS.ATTACHMENTS.POST,
      data: formData
    })
      .then(({ data: { count } }) => {
        dispatch(setDetails({ counts: { [TAB_KEYS.ATTACHMENT]: count } }));

        setTimeout(() => {
          dispatch(
            showSnackbar({
              message: successMessageFormatter(EntityTypes.ATTACHMENTS, ACTION_MODES.Upload),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
        }, NAVIGATE_TOASTER_TIMEOUT);

        return count;
      })
      .catch((error) => {
        setTimeout(() => {
          dispatch(
            showSnackbar({
              message: errorMessageFormatter(error, EntityTypes.ATTACHMENTS, ACTION_MODES.Upload),
              severity: SnackbarSeverityTypes.ERROR
            })
          );
        }, NAVIGATE_TOASTER_TIMEOUT);
        return rejectWithValue(error.response);
      });
  },
  {
    condition: (entityId) => Boolean(entityId),
    dispatchConditionRejection: true
  }
);

export const attachmentsSlice = createSlice({
  name: ATTACHMENTS_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    filterUpdate: (state, { payload }) => {
      state.filter = {
        ...state.filter,
        page: payload?.value?.page ?? 0,
        size: payload?.value?.size ?? state.filter.size,
        search: payload?.value?.search ?? state.filter.search,
        match: payload?.value?.match ?? state.filter.match,
        searchValue: payload?.value?.searchValue ?? state.filter.searchValue
      };
    },
    setSelectionModel: (state, { payload }) => {
      state.selectionModel = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAttachments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAttachments.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.data = payload.content;
        state.filter = {
          ...state.filter,
          page: payload?.pageable?.pageNumber ?? state.filter.page,
          size: payload?.pageable?.pageSize ?? state.filter.size
        };
        state.totalElements = payload?.totalElements ?? state.totalElements;
      })
      .addCase(getAttachments.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
        state.isError = true;
        state.totalElements = 0;
      })
      .addCase(removeAttachments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeAttachments.fulfilled, (state, { payload }) => {
        state.totalElements = payload;
        state.filter.page = Math.min(
          state.filter.page,
          Math.max(0, Math.floor((state.totalElements - 1) / state.filter.size))
        );
        state.isLoading = false;
        state.selectionModel = [];
      })
      .addCase(removeAttachments.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })
      .addCase(uploadAttachment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadAttachment.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalElements = payload;
      })
      .addCase(uploadAttachment.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      });
  }
});

export const selectIsLoading = (state) => state.ATTACHMENTS_SLICE.isLoading;
export const selectData = (state) => state.ATTACHMENTS_SLICE.data;
export const selectFilter = (state) => state.ATTACHMENTS_SLICE.filter;
export const selectTotalElements = (state) => state.ATTACHMENTS_SLICE.totalElements;
export const selectSelectionModel = (state) => state.ATTACHMENTS_SLICE.selectionModel;

const { actions, reducer } = attachmentsSlice;

export const { resetState, setLoading, filterUpdate, setSelectionModel } = actions;

export default reducer;
