import React from 'react';

import NoData from '@components/NoData';
import Tabs from '@components/Tabs';
import tabsConfig, { EVENTS_TABS_COMPONENT } from '@pages/Events/components/Tabs/tabsConfig';

const EventsTabs = ({ entityId = '', entityType = '', details = null, setDetails = () => {} }) => {
  return (
    <Tabs
      tabsConfig={tabsConfig}
      count={details?.counts}
      renderContent={(tab) => {
        const DynamicComponent = EVENTS_TABS_COMPONENT[tab?.key];
        return DynamicComponent ? (
          <DynamicComponent
            entityId={entityId}
            entityType={entityType}
            columns={tab.columns}
            toolbarOptions={tab.options}
            allowedEntityId={8}
            setDetails={setDetails}
            subColumns={tab?.subColumns}
            count={details?.counts}
            key={entityId}
          />
        ) : (
          <NoData />
        );
      }}
    ></Tabs>
  );
};

export default EventsTabs;
