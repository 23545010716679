import { isNil, omit } from 'lodash';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ACTION_MODES, NAVIGATE_TOASTER_TIMEOUT, TAB_KEYS } from '@common/Constants';
import { deepMerge } from '@common/helpers/deepMerge';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { PARTICIPANTS_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { closeSidebar } from '@components/RightSidebar/rightSidebarSlice';
import { SnackbarSeverityTypes, showSnackbar } from '@components/Snackbar/snackbarSlice';
import { TABLE_OPTIONS } from '@config/network';
import { setLocationDetails } from '@pages/BusinessPartners/businessPartnersSlice';
import { getByPathAndParams, postByPathAndData, putByPathAndData } from '@services/BaseApi';

export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    // WAITING FOR THE BE
    // sortBy: SORT_DATA_CONTACTS[0].name,
    // sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0]
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: [],
  details: null
};

export const LOCATION_ACCOMMODATION_SLICE = 'locationAccommodation';

export const fetchAccommodations = createAsyncThunk(
  `${LOCATION_ACCOMMODATION_SLICE}/getAll`,
  ({ entityId, filter }) => {
    return getByPathAndParams({
      path: PARTICIPANTS_PATHS.HOTELS_ACCOMMODATIONS,
      pathVariables: { id: entityId },
      params: filter
    })
      .then((response) => response.data)
      .catch((error) => error);
  }
);

export const createAccommodations = createAsyncThunk(
  `${LOCATION_ACCOMMODATION_SLICE}/create`,
  ({ entityId, values, setSubmitting, navigate }, { rejectWithValue, dispatch }) => {
    return postByPathAndData({
      path: PARTICIPANTS_PATHS.HOTELS_ACCOMMODATIONS,
      data: {
        hotelRoomTypeId: values.roomType,
        maxOccupancy: values.maxOccupancy,
        description: values.description
      },
      pathVariables: { id: entityId }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.ACCOMMODATION, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        dispatch(setLocationDetails({ counts: { [TAB_KEYS.ACCOMMODATION]: response.data.count } }));

        setTimeout(() => {
          navigate({
            search: (previousUrlParams) => {
              const newParams = { ...omit(previousUrlParams, 'mode') };
              return { ...newParams, activeTab: TAB_KEYS.ACCOMMODATION };
            }
          });
        }, NAVIGATE_TOASTER_TIMEOUT);

        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.PARTICIPANT, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error.response);
      })
      .finally(() => setSubmitting(false));
  }
);

export const patchAccommodations = createAsyncThunk(
  `${LOCATION_ACCOMMODATION_SLICE}/patch`,
  ({ data, id }, { rejectWithValue, dispatch }) => {
    return putByPathAndData({
      path: PARTICIPANTS_PATHS.UPDATE_HOTELS_ACCOMMODATIONS,
      data: {
        hotelRoomTypeId: data.roomType.id,
        ...omit(data, 'roomType')
      },
      pathVariables: { id: id }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.ACCOMMODATION, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.PARTICIPANT, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error.response);
      });
  }
);

export const accommodationTableSlice = createSlice({
  name: LOCATION_ACCOMMODATION_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setMode: (state, { payload }) => {
      state.mode = payload;
    },
    setDetails: (state, { payload }) => {
      state.details = payload;
    },
    setNewAccommodation: (state, { payload }) => {
      state.newAccommodation = payload;
    },
    setErrors: (state, { payload }) => {
      state.createErrors = payload;
    },
    setCancelCreateMode: (state) => {
      state.mode = 'details';
      state.newAccommodation = {
        roomType: '',
        maxOccupancy: '',
        hotel: null,
        description: ''
      };
      state.createErrors = {};
    },
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};
      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }
      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }
      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchAccommodations.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.data = payload?.content ?? [];
      })
      .addCase(fetchAccommodations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAccommodations.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      .addCase(createAccommodations.fulfilled, (state, { payload }) => {
        state.data = [payload.content, ...state.data];
        state.isLoading = false;
        state.totalElements += 1;
      })
      .addCase(createAccommodations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAccommodations.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(patchAccommodations.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(patchAccommodations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patchAccommodations.fulfilled, (state, { meta }) => {
        state.data = state.data.map((item) =>
          item.id === meta.arg.id ? deepMerge(item, meta.arg.data) : item
        );
        state.details = deepMerge(state.details, meta.arg.data);
        state.isLoading = false;
      })
      .addCase(closeSidebar, (state) => {
        state.details = null;
      });
  }
});

export const selectList = (state) => state.LOCATION_ACCOMMODATION_SLICE.data;
export const selectRows = (state) => state.LOCATION_ACCOMMODATION_SLICE.rows;
export const selectTotalElements = (state) => state.LOCATION_ACCOMMODATION_SLICE.totalElements;
export const selectTotalPages = (state) => state.LOCATION_ACCOMMODATION_SLICE.totalPages;
export const selectFilter = (state) => state.LOCATION_ACCOMMODATION_SLICE.filter;
export const selectIsLoading = (state) => state.LOCATION_ACCOMMODATION_SLICE.isLoading;
export const selectSelectionModel = (state) => state.LOCATION_ACCOMMODATION_SLICE.selectionModel;
export const selectMode = (state) => state.LOCATION_ACCOMMODATION_SLICE.mode;
export const selectNewAccommodation = (state) =>
  state.LOCATION_ACCOMMODATION_SLICE.newAccommodation;
export const selectErrors = (state) => state.LOCATION_ACCOMMODATION_SLICE.createErrors;
export const selectDetails = (state) => state.LOCATION_ACCOMMODATION_SLICE.details;

const { actions, reducer } = accommodationTableSlice;

export const {
  setData,
  setFilterParams,
  resetState,
  setSelectionModel,
  setMode,
  setNewAccommodation,
  setErrors,
  setCancelCreateMode,
  setDetails
} = actions;

export default reducer;
