import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';

import { omit } from 'lodash';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { SORTING_ORDER } from '@common/Constants';
import getAdvancedFiltersForMUITable from '@common/helpers/tables/getAdvancedFiltersForMUITable';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import { getFilterOperatorsBasedOnType } from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';

import {
  selectList,
  fetchTeams,
  selectFilter,
  selectIsLoading,
  resetState,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  selectSelectionModel,
  setSelectionModel
} from './teamTableSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  noNightsBooked: { color: theme.palette.error.main },
  someNightsBooked: { color: theme.palette.warning.main },
  allNightsBooked: { color: theme.palette.success.light }
}));

const statusColor = (nightsBookedData, classes) => {
  if (!nightsBookedData) {
    return;
  }

  const nightsBooked = nightsBookedData.slice(0, nightsBookedData.indexOf('/'));
  const nightsTotal = nightsBookedData.slice(nightsBookedData.indexOf('/') + 1);

  return nightsBooked === '0'
    ? classes.noNightsBooked
    : nightsBooked !== nightsTotal
    ? classes.someNightsBooked
    : classes.allNightsBooked;
};

const TeamTable = ({ columns = [], entityId = null, entityType = null, toolbarOptions = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);
  const hasHotelRequest = (data) => data.some((row) => row?.hotelRequest);

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(fetchTeams({ entityId: entityId, filter: filter }));
    }
  }, [
    entityId,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.size,
    filter?.filters
  ]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add_participant':
        return !entityId || !entityType;
      case 'delete':
        return true; // selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onToolbarItemClick = (fieldName) => {
    switch (fieldName) {
      // case 'add_participant':
      //   navigate({
      //     search: (previousUrlParams) => ({ ...previousUrlParams, mode: ACTION_MODES.Create })
      //   });
      //   break;
      case 'delete':
        return true;
      default:
        return false;
    }
  };

  const onFilterChange = (values) => {
    const advancedFilters = getAdvancedFiltersForMUITable(values, () =>
      dispatch(fetchTeams({ entityId: entityId, filter: { ...omit(filter, 'filters') } }))
    );

    dispatch(setFilterParams({ key: 'filters', value: { advancedFilters } }));
  };

  const onSortChange = (sortModel) =>
    dispatch(
      setFilterParams([
        { key: 'sortBy', value: sortModel[0].field },
        { key: 'sortDirection', value: sortModel[0].sort }
      ])
    );

  return (
    <div className={classes.root}>
      <TabContent>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h6" color="primary">
            {Localize.get('IltSession.Teams')}
          </Typography>
          <Toolbar className={classes.toolbar}>
            {toolbarOptions?.map((item, index) => (
              <ToolbarItem
                key={index}
                item={item}
                isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                onToolbarItemClick={onToolbarItemClick}
              />
            ))}
          </Toolbar>
        </div>
        <TabContainer>
          <StyledDataGrid
            keepNonExistentRowsSelected={true}
            loading={isLoading}
            rows={data}
            columns={
              hasHotelRequest(data)
                ? columns.map((column) => ({
                    ...column,
                    headerName: Localize.get(column.headerName),
                    filterOperators: getFilterOperatorsBasedOnType(column?.type),
                    renderCell: (params) => (
                      <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                        {['nightsBooked'].includes(params.field) ? (
                          <Typography
                            className={statusColor(params?.value, classes)}
                            variant="body"
                            component="div"
                          >
                            {params.value}
                          </Typography>
                        ) : ['name', 'company', 'hotel', 'email'].includes(params.field) ? (
                          <Tooltip title={params.value} arrow>
                            <Typography
                              noWrap
                              variant="body"
                              component="div"
                              sx={{
                                maxWidth: params?.colDef?.width - 10 // 10 px for three dots,
                              }}
                            >
                              {params.value}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography variant="body" component="div">
                            {params.value}
                          </Typography>
                        )}
                      </div>
                    )
                  }))
                : columns
                    .filter(
                      (column) =>
                        column.headerName !== 'IltSession.Hotel' &&
                        column.headerName !== 'IltSession.Arrival' &&
                        column.headerName !== 'IltSession.Departure' &&
                        column.headerName !== 'IltSession.NightsBooked'
                    )
                    .map((column) => ({
                      ...column,
                      headerName: Localize.get(column.headerName),
                      renderCell: (params) => (
                        <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                          {['nightsBooked'].includes(params.field) ? (
                            <Typography
                              className={statusColor(params?.value, classes)}
                              variant="body"
                              component="div"
                            >
                              {params.value}
                            </Typography>
                          ) : ['name', 'company'].includes(params.field) ? (
                            <Tooltip title={params.value} arrow>
                              <Typography
                                variant="body"
                                component="div"
                                sx={{
                                  maxWidth: params?.colDef?.width,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                                }}
                              >
                                {params.value}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography variant="body" component="div">
                              {params.value}
                            </Typography>
                          )}
                        </div>
                      )
                    }))
            }
            initialState={{
              pagination: {
                pageSize: totalPages,
                rowCount: totalElements,
                page: filter?.page
              }
            }}
            onSelectionModelChange={(newSelectionModel) => {
              dispatch(setSelectionModel(newSelectionModel));
            }}
            selectionModel={selectionModel}
            checkboxSelection
            localeText={getLocalesText(Localize)}
            pagination
            paginationMode="server"
            disableColumnMenu
            page={filter?.page}
            pageSize={filter?.size}
            rowCount={totalElements}
            rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
            disableSelectionOnClick
            components={{ Toolbar: () => <TableToolbar /> }}
            onPageChange={(page) => dispatch(setFilterParams({ page, selectionModel }))}
            onPageSizeChange={(value) =>
              dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
            }
            sortingOrder={SORTING_ORDER}
            onSortModelChange={onSortChange}
            onFilterModelChange={onFilterChange}
          />
        </TabContainer>
      </TabContent>
    </div>
  );
};

export default TeamTable;
