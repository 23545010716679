import Localize from 'react-intl-universal';

import * as yup from 'yup';

export const participantSchema = () =>
  yup.object().shape({
    hotelRequest: yup.boolean(),
    departureDate: yup
      .date()
      .nullable()
      .when('hotelRequest', {
        is: true,
        then: yup
          .date()
          .nullable()
          .min(yup.ref('arrivalDate'), Localize.get('ValidationMessages.DepartureArrivalRule'))
          .required(Localize.get('ValidationMessages.DateRequired'))
          .typeError(Localize.get('ValidationMessages.DateInvalid'))
      }),
    arrivalDate: yup
      .date()
      .nullable()
      .when('hotelRequest', {
        is: true,
        then: yup
          .date()
          .nullable()
          .max(yup.ref('departureDate'), Localize.get('ValidationMessages.ArrivalDepartureRule'))
          .required(Localize.get('ValidationMessages.DateRequired'))
          .typeError(Localize.get('ValidationMessages.DateInvalid'))
      })
  });
