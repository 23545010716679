import { Navigate } from 'react-location';

import { SETTINGS_PATH } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import Employees, { CreateEmployee } from '@pages/Employees';
import { getByPathAndParams } from '@services/BaseApi';

import { employeesPath, newPath, rootPath } from '../Routes';

const EmployeeRoute = (routeCache) => ({
  path: employeesPath,
  children: [
    {
      path: rootPath,
      element: <Employees />,
      loader: routeCache.createLoader(
        async () => ({
          config: await getByPathAndParams({
            path: SETTINGS_PATH.GET,
            pathVariables: { tile: EntityTypes.EMPLOYEE }
          })
        }),
        { policy: 'cache-first' }
      )
    },
    {
      path: newPath,
      element: <CreateEmployee />
    },
    {
      element: <Navigate to={employeesPath} />
    }
  ]
});

export default EmployeeRoute;
