import { isNil } from 'lodash';
import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { dateInitFormats, SORT_DIRECTION } from '@common/Constants';
import { ILT_SESSION_PATHS } from '@common/network/ApiPaths';
import { TABLE_OPTIONS } from '@config/network';
import { getByPathAndParams } from '@services/BaseApi';

export const initialState = {
  data: [],
  rows: [],
  selectedId: null,
  filter: {
    search: '',
    sortBy: '',
    sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0],
    filters: null
  },
  totalPages: 0,
  details: null,
  totalElements: 0,
  isLoading: false,
  selectionModel: []
};

export const ILT_SESSION_TEAM_SLICE = 'iltSessionTeam';

export const fetchTeams = createAsyncThunk(
  `${ILT_SESSION_TEAM_SLICE}/fetchTeams`,
  ({ entityId, filter }, { rejectWithValue }) => {
    return getByPathAndParams({
      path: ILT_SESSION_PATHS.GET_TEAMS,
      pathVariables: { iltSessionId: entityId },
      params: filter
    })
      .then((response) => response.data)
      .catch((error) => {
        return rejectWithValue(error.response);
      });
  }
);

export const iltSessionWaitingListTableSlice = createSlice({
  name: ILT_SESSION_TEAM_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      const newFilterValues = Array.isArray(action.payload)
        ? action.payload.reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {})
        : { [action.payload.key]: action.payload.value };

      state.filter = {
        ...state.filter,
        ...newFilterValues,
        page: action.payload.page ?? 0
      };
      state.isLoading = !!(action.payload.key === 'search' && !action.payload.value);
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchTeams.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.data = payload?.content.map((el) => ({
          ...el,
          registrationDatetime: moment(el.registrationDatetime).format(dateInitFormats.basicDate)
        }));
      })
      .addCase(fetchTeams.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTeams.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      });
  }
});

export const selectId = (state) => state.ILT_SESSION_SLICE.selectedId;
export const selectList = (state) => state.ILT_SESSION_TEAM_SLICE.data;
export const selectRows = (state) => state.ILT_SESSION_TEAM_SLICE.rows;
export const selectTotalElements = (state) => state.ILT_SESSION_TEAM_SLICE.totalElements;
export const selectTotalPages = (state) => state.ILT_SESSION_TEAM_SLICE.totalPages;
export const selectFilter = (state) => state.ILT_SESSION_TEAM_SLICE.filter;
export const selectIsLoading = (state) => state.ILT_SESSION_TEAM_SLICE.isLoading;
export const selectSelectionModel = (state) => state.ILT_SESSION_TEAM_SLICE.selectionModel;

const { actions, reducer } = iltSessionWaitingListTableSlice;

export const { setData, setSelectedId, setFilterParams, resetState, setSelectionModel } = actions;

export default reducer;
