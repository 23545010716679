import React from 'react';

import NoData from '@components/NoData';
import Tabs from '@components/Tabs';
import tabsConfig, { CONTACTS_TABS_COMPONENTS } from '@pages/Contacts/components/Tabs/tabsConfig';

const ContactsTabs = ({
  entityId = '',
  entityType = '',
  details = null,
  setDetails = () => {}
}) => (
  <Tabs
    tabsConfig={tabsConfig}
    count={details?.counts}
    renderContent={(tab) => {
      const DynamicComponent = CONTACTS_TABS_COMPONENTS[tab?.key];
      return DynamicComponent ? (
        <DynamicComponent
          entityId={entityId}
          entityType={entityType}
          columns={tab.columns}
          toolbarOptions={tab.options}
          allowedEntityId={8}
          setDetails={setDetails}
          key={entityId}
        />
      ) : (
        <NoData />
      );
    }}
  ></Tabs>
);

export default ContactsTabs;
