import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { dateFormatTime } from '@common/Constants';
import { CONTACTS_PATHS } from '@common/network/ApiPaths';
import { TABLE_OPTIONS } from '@config/network';
import { getByPathAndParams } from '@services/BaseApi';

export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0]
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: []
};

export const CONTACT_PARTICIPATIONS = 'contactParticipations';

export const fetchParticipations = createAsyncThunk(
  `${CONTACT_PARTICIPATIONS}/participations`,
  async ({ entityId, filter }, { rejectWithValue }) => {
    try {
      const response = await getByPathAndParams({
        path: CONTACTS_PATHS.GET_PARTICIPATIONS,
        pathVariables: { id: entityId },
        params: filter
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const contactParticipationsTableSlice = createSlice({
  name: CONTACT_PARTICIPATIONS,
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      const newFilterValues = Array.isArray(action.payload)
        ? action.payload.reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {})
        : { [action.payload.key]: action.payload.value };

      state.filter = {
        ...state.filter,
        ...newFilterValues,
        page: action.payload.page ?? 0
      };
      state.isLoading = !!(action.payload.key === 'search' && !action.payload.value);
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchParticipations.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = payload?.totalElements || state.totalElements;
        state.data = payload?.content.map((el) => ({
          ...el,
          startDateTime: moment(el.startDateTime).format(dateFormatTime.dateTime),
          endDateTime: moment(el.endDateTime).format(dateFormatTime.dateTime)
        }));
      })
      .addCase(fetchParticipations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchParticipations.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      });
  }
});
export const selectList = (state) => state.CONTACT_PARTICIPATIONS.data;
export const selectRows = (state) => state.CONTACT_PARTICIPATIONS.rows;
export const selectTotalElements = (state) => state.CONTACT_PARTICIPATIONS.totalElements;
export const selectTotalPages = (state) => state.CONTACT_PARTICIPATIONS.totalPages;
export const selectFilter = (state) => state.CONTACT_PARTICIPATIONS.filter;
export const selectIsLoading = (state) => state.CONTACT_PARTICIPATIONS.isLoading;
export const selectSelectionModel = (state) => state.CONTACT_PARTICIPATIONS.selectionModel;
const { actions, reducer } = contactParticipationsTableSlice;
export const { setData, setFilterParams, resetState, setSelectionModel } = actions;

export default reducer;
