import { Navigate } from 'react-location';

import {
  EVENTS_PATHS,
  ILT_SESSION_DAY_PATHS,
  ILT_SESSION_PATHS,
  LOCALES_PATHS,
  PARTICIPANTS_PATHS,
  ACTIVITIES_PATHS,
  SETTINGS_PATH
} from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import IltSession, { CreateIltSession } from '@pages/IltSession';
import Hotels from '@pages/IltSession/components/Hotels/Hotels';
import SessionDay from '@pages/IltSession/components/SessionDay/SessionDay';
import BookingCalendar from '@pages/IltSession/pages/Booking/components/BookingCalendar';
import Details from '@pages/IltSession/pages/Details/Details';
import { getByPathAndParams } from '@services/BaseApi';

import {
  iltSessionsDetailsSlug,
  iltSessionsDetailsSessionDaySlug,
  iltSessionsPath,
  newPath,
  rootPath,
  iltSessionsDetailsHotelSlug,
  iltSessionsHotelBookingSlug
} from '../Routes';

const IltSessionRoute = (routeCache) => ({
  path: iltSessionsPath,
  children: [
    {
      path: rootPath,
      element: <IltSession />,
      loader: routeCache.createLoader(
        async () => ({
          settings: await getByPathAndParams({
            path: SETTINGS_PATH.GET.replace(':tile', EntityTypes.ILT_SESSION)
          }),
          status: await getByPathAndParams({
            path: EVENTS_PATHS.EVENTS_STATUSES
          }),
          type: await getByPathAndParams({
            path: EVENTS_PATHS.EVENTS_TYPES
          }),
          priceType: await getByPathAndParams({
            path: ILT_SESSION_PATHS.PRICE_TYPES
          }),
          currencyType: await getByPathAndParams({
            path: LOCALES_PATHS.CURRENCIES
          }),
          participantStatuses: await getByPathAndParams({
            path: PARTICIPANTS_PATHS.PARTICIPANT_STATUSES
          }),
          participantData: await getByPathAndParams({
            path: PARTICIPANTS_PATHS.PARTICIPANT_FORM
          }),
          activityStatuses: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_STATUSES
          }),
          activityPriorities: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_PRIORITY
          }),
          activityTaskCategories: await getByPathAndParams({
            path: ACTIVITIES_PATHS.ACTIVITIES_TASK_CATEGORIES
          })
        }),
        { policy: 'cache-first' }
      )
    },
    {
      path: newPath,
      element: <CreateIltSession />,
      loader: routeCache.createLoader(async () => ({
        type: await getByPathAndParams({
          path: EVENTS_PATHS.EVENTS_TYPES
        }),
        status: await getByPathAndParams({
          path: EVENTS_PATHS.EVENTS_STATUSES
        })
      }))
    },
    {
      path: iltSessionsDetailsSlug,
      element: null,
      meta: {
        breadcrumb: (params, session) => {
          return session?.name;
        }
      },
      loader: async ({ params: { sessionId } }) => ({
        session: await getByPathAndParams({
          path: ILT_SESSION_PATHS.GET_DETAILS,
          pathVariables: { id: sessionId }
        }),
        type: await getByPathAndParams({
          path: EVENTS_PATHS.EVENTS_TYPES
        }),
        status: await getByPathAndParams({
          path: EVENTS_PATHS.EVENTS_STATUSES
        }),
        currencyType: await getByPathAndParams({
          path: LOCALES_PATHS.CURRENCIES
        })
      }),
      children: [
        {
          path: '/',
          element: <Details />
        },
        {
          path: iltSessionsDetailsHotelSlug,
          element: <Hotels />,
          loader: async ({ params: { id } }) => {
            return {
              hotelDetails: await getByPathAndParams({
                path: ILT_SESSION_PATHS.GET_CONTINGENTS_HOTEL,
                pathVariables: { id }
              })
            };
          },
          meta: {
            breadcrumb: (params, hotelDetails) => {
              return hotelDetails?.hotel?.name;
            }
          }
        },
        {
          path: iltSessionsDetailsSessionDaySlug,
          element: <SessionDay />,
          loader: async ({ params: { id } }) => ({
            sessionDayDetails: await getByPathAndParams({
              path: ILT_SESSION_DAY_PATHS.GET_SESSION_DAY,
              pathVariables: { id }
            })
          }),
          meta: {
            breadcrumb: (params, sessionDay) => {
              return sessionDay?.name;
            }
          }
        },
        {
          path: iltSessionsHotelBookingSlug,
          element: <BookingCalendar />,
          meta: {
            breadcrumb: (params, hotel) => {
              return hotel?.name;
            }
          },
          loader: async ({ params: { id } }) => ({
            hotel: await getByPathAndParams({
              path: ILT_SESSION_PATHS.GET_CONTINGENTS_HOTEL,
              pathVariables: { id }
            })
          })
        },
        {
          element: <Navigate to={iltSessionsPath} />
        }
      ]
    },
    {
      element: <Navigate to={iltSessionsPath} />
    }
  ]
});

export default IltSessionRoute;
