import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useSearch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import { replaceAll } from '@common/helpers/string';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import { saveState } from '@common/storage/persistSlice';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import { TABLE_OPTIONS } from '@config/network';
import { eventsDetailsHotelPath } from '@navigation/routes/Routes';
import {
  fetchHotels,
  selectFilter,
  selectIsLoading,
  selectList,
  selectSelectionModel,
  selectTotalElements,
  selectTotalPages,
  resetState,
  setFilterParams,
  setSelectionModel,
  deleteHotels
} from '@pages/Events/components/Tabs/Hotel/hotelTableSlice';
import { selectFilter as selectEventsFilter } from '@pages/Events/eventsSlice';

import CreateHotelContingent from '../../Hotels/CreateHotelContingent/CreateHotelContingent';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  }
}));

const HotelContingentTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarOptions = []
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useSearch();
  const eventsFilter = useSelector(selectEventsFilter);
  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(fetchHotels({ entityId: entityId, filter: filter }));
    }
  }, [entityId, filter?.page, filter?.sortBy, filter?.sortDirection, filter?.size]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add_sessions':
        return !entityId || !entityType;

      case 'delete':
        return selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onToolbarItemClick = (fieldName) => {
    switch (fieldName) {
      case 'add_sessions':
        dispatch(saveState({ filter: eventsFilter, selectedId: entityId }));
        navigate({
          search: (previousUrlParams) => ({ ...previousUrlParams, mode: ACTION_MODES.Create })
        });
        break;
      case 'delete': {
        dispatch(
          openConfirmDialog({
            title: Localize.get('ConfirmationMessages.Delete', {
              item: Localize.get('Events.SingularOrPluralHotel', {
                selectionModel: selectionModel.length
              })?.toLowerCase()
            }),
            confirmButton: Localize.get('Buttons.Delete'),
            cancelButton: Localize.get('Buttons.Cancel'),
            disabler: { confirm: isLoading, cancel: isLoading }
          })
        )
          .unwrap()
          .then((result) => {
            if (result === CONFIRM_ACTIONS.Confirm) {
              dispatch(deleteHotels({ contingentIds: selectionModel, id: entityId }));
            }
          });
        return true;
      }
      default:
        return false;
    }
  };

  const onRowClick = (row) => {
    const { id } = row;
    const replaceMap = { ':eventId': entityId, ':id': id };
    const path = replaceAll(eventsDetailsHotelPath, replaceMap);

    dispatch(saveState({ eventsFilter, selectedId: entityId }));
    navigate({ to: path, search: () => ({ activeTab: TAB_KEYS.OVERVIEW }) });
  };

  return (
    <div className={classes.root}>
      {search?.mode === ACTION_MODES.Create ? (
        <CreateHotelContingent />
      ) : (
        <TabContent>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('IltSession.HotelsContingentAndBookings')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              {toolbarOptions?.map((item, index) => (
                <ToolbarItem
                  key={index}
                  item={item}
                  isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                  onToolbarItemClick={onToolbarItemClick}
                />
              ))}
            </Toolbar>
          </div>
          <TabContainer>
            <StyledDataGrid
              keepNonExistentRowsSelected={true}
              loading={isLoading}
              rows={data}
              columns={[
                ...columns.map((column) => ({
                  ...column,
                  headerName: Localize.get(column.headerName),
                  renderCell: (params) => (
                    <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                      {['description', 'roomType'].includes(params.field) ? (
                        <Tooltip title={params.value} arrow>
                          <Typography noWrap={true} variant="body" component="div">
                            {params.value}
                          </Typography>
                        </Tooltip>
                      ) : ['hotel'].includes(params.field) ? (
                        <Tooltip title={params.value} arrow>
                          <Typography variant="body" component="div" noWrap={true}>
                            <Link onClick={() => onRowClick(params)} sx={{ cursor: 'pointer' }}>
                              {params?.value}
                            </Link>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography variant="body" component="div">
                          {params.value}
                        </Typography>
                      )}
                    </div>
                  )
                }))
              ]}
              initialState={{
                pagination: {
                  pageSize: totalPages,
                  rowCount: totalElements,
                  page: filter?.page
                }
              }}
              onSelectionModelChange={(newSelectionModel) => {
                dispatch(setSelectionModel(newSelectionModel));
              }}
              selectionModel={selectionModel}
              checkboxSelection
              localeText={getLocalesText(Localize)}
              pagination
              paginationMode="server"
              disableColumnMenu
              page={filter?.page}
              pageSize={filter?.size}
              rowCount={totalElements}
              disableSelectionOnClick
              rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
              components={{
                Toolbar: () => <TableToolbar />
              }}
              onPageChange={(page) => dispatch(setFilterParams({ page, selectionModel }))}
              onPageSizeChange={(value) =>
                dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
              }
            />
          </TabContainer>
        </TabContent>
      )}
    </div>
  );
};

export default HotelContingentTable;
