import Localize from 'react-intl-universal';

import * as yup from 'yup';

export const participantSchema = () =>
  yup.object().shape({
    standardPrice: yup
      .number()
      .nullable()
      .when('currency', {
        is: (currency) => !!currency,
        then: yup
          .number()
          .typeError(Localize.get('ValidationMessages.PriceRequired'))
          .min(0, Localize.get('ValidationMessages.PositiveNumber'))
          .required(Localize.get('ValidationMessages.PriceRequired'))
      }),

    advertisingSubsidyPrice: yup
      .number()
      .nullable()
      .when('currency', {
        is: (currency) => !!currency,
        then: yup
          .number()
          .typeError(Localize.get('ValidationMessages.PriceRequired'))
          .min(0, Localize.get('ValidationMessages.PositiveNumber'))
          .required(Localize.get('ValidationMessages.PriceRequired'))
      }),
    hotelRequest: yup.boolean(),
    departureDate: yup
      .date()
      .nullable()
      .when('hotelRequest', {
        is: true,
        then: yup
          .date()
          .nullable()
          .min(yup.ref('arrivalDate'), Localize.get('ValidationMessages.DepartureArrivalRule'))
          .required(Localize.get('ValidationMessages.DateRequired'))
          .typeError(Localize.get('ValidationMessages.DateInvalid'))
      }),
    arrivalDate: yup
      .date()
      .nullable()
      .when('hotelRequest', {
        is: true,
        then: yup
          .date()
          .nullable()
          .max(yup.ref('departureDate'), Localize.get('ValidationMessages.ArrivalDepartureRule'))
          .required(Localize.get('ValidationMessages.DateRequired'))
          .typeError(Localize.get('ValidationMessages.DateInvalid'))
      })
  });
