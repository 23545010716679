import { isNil } from 'lodash';
import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  ACTION_MODES,
  dateFormatTime,
  dateInitFormats,
  SORT_DIRECTION,
  TAB_KEYS
} from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import { deepMerge } from '@common/helpers/deepMerge';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { ILT_SESSION_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { TABLE_OPTIONS } from '@config/network';
import { setDetails } from '@pages/IltSession/iltSessionSlice';
import { deleteByPath, getByPathAndParams, postByPathAndData } from '@services/BaseApi';

import tasksInitialValues from './CreateTask/utils/tasksInitialValues';

export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    sortBy: '',
    sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0],
    filters: null
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: [],
  isPopupOpen: false,
  anchorEl: null
};

export const ILT_SESSION_TASKS_SLICE = 'iltSessionTasks';

export const fetchTasks = createAsyncThunk(
  `${ILT_SESSION_TASKS_SLICE}/tasks`,
  async ({ entityId, filter }, { rejectWithValue }) => {
    try {
      const response = await getByPathAndParams({
        path: ILT_SESSION_PATHS.GET_TASKS,
        pathVariables: { id: entityId },
        params: filter
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

// create task
export const createTask = createAsyncThunk(
  `${ILT_SESSION_TASKS_SLICE}/create`,
  async ({ data, sessionId }, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState();
      const { startDateTime, endDateTime } = data;
      const { details } = state.ILT_SESSION_SLICE;
      const formatted = {
        startDateTime: dateToFormat(startDateTime, dateInitFormats.dateTime),
        endDateTime: dateToFormat(endDateTime, dateInitFormats.dateTime)
      };

      const response = await postByPathAndData({
        path: ILT_SESSION_PATHS.POST_TASK.replace(':id', sessionId),
        data: {
          subject: data?.subject,
          startDateTime: formatted.startDateTime.split('.')[0],
          endDateTime: formatted.endDateTime.split('.')[0],
          statusId: data?.statusId,
          priorityId: data?.priorityId,
          taskDto: {
            completion: !data?.taskDto?.completion
              ? tasksInitialValues?.taskDto?.completion
              : parseInt(data?.taskDto?.completion),
            processorId: data?.taskDto?.processor?.id || tasksInitialValues?.taskDto?.processor,
            taskCategoryId: data?.taskDto?.taskCategory,
            eventId: null
          },
          // type task of activity is mandatory 1 is for appointment/activity
          typeId: 2,
          ownerId: data?.owner?.id,
          accountId: data?.account?.organizationPersonId || tasksInitialValues?.account,
          contactId: data?.contact?.id || tasksInitialValues?.contact
        }
      });
      dispatch(
        showSnackbar({
          message: successMessageFormatter(EntityTypes.TASK, ACTION_MODES.Create),
          severity: SnackbarSeverityTypes.SUCCESS
        })
      );

      dispatch(
        fetchTasks({
          entityId: getState().ILT_SESSION_SLICE.selectedId,
          filter: getState().ILT_SESSION_TASKS_SLICE.filter
        })
      );

      dispatch(
        setDetails({
          ...details,
          counts: {
            ...details.counts,
            task: response.data.counts.task
          }
        })
      );

      return response.data;
    } catch (error) {
      dispatch(
        showSnackbar({
          message: errorMessageFormatter(error, EntityTypes.TASK, ACTION_MODES.Create),
          severity: SnackbarSeverityTypes.ERROR
        })
      );

      return rejectWithValue(error?.response);
    }
  }
);

export const deleteTasks = createAsyncThunk(
  `${ILT_SESSION_TASKS_SLICE}delete`,
  async ({ id, taskIds: taskId }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await deleteByPath({
        path: ILT_SESSION_PATHS.DELETE_TASK,
        pathVariables: { id, taskIds: taskId }
      });

      dispatch(fetchTasks({ entityId: id, filter: getState().ILT_SESSION_TASKS_SLICE.filter }));
      dispatch(setDetails({ counts: { [TAB_KEYS.TASK]: response.data.count } }));
      dispatch(
        showSnackbar({
          message: successMessageFormatter(EntityTypes.TASK, ACTION_MODES.Delete),
          severity: SnackbarSeverityTypes.SUCCESS
        })
      );

      return { response, taskIds: taskId };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

//select task
export const saveTasks = createAsyncThunk(
  `${ILT_SESSION_TASKS_SLICE}/save`,
  (postData, { getState, rejectWithValue, dispatch }) => {
    const state = getState();
    const { details } = state.ILT_SESSION_SLICE;

    return postByPathAndData({
      path: ILT_SESSION_PATHS.POST_TASKS,
      data: {},
      pathVariables: {
        iltSessionId: postData.iltSessionId,
        taskIds: postData.ids.map(({ id }) => id)
      }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.TASK, ACTION_MODES.Add),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        dispatch(
          setDetails({
            ...details,
            counts: {
              ...details.counts,
              task: response.data[0].counts.task
            }
          })
        );

        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.TASK, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
        rejectWithValue(error.response);
      });
  }
);

export const iltSessionTasksTableSlice = createSlice({
  name: ILT_SESSION_TASKS_SLICE,
  initialState,
  reducers: {
    setPopupIsOpen: (state, { payload }) => {
      state.anchorEl = payload;
      state.isPopupOpen = true;
    },
    setPopupIsClose: (state) => {
      state.anchorEl = null;
      state.isPopupOpen = false;
    },
    setSelectedItems: (state, { payload }) => {
      state.selectedItems = payload;
    },
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      const newFilterValues = Array.isArray(action.payload)
        ? action.payload.reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {})
        : { [action.payload.key]: action.payload.value };

      state.filter = {
        ...state.filter,
        ...newFilterValues,
        page: action.payload.page ?? 0
      };
      state.isLoading = !!(action.payload.key === 'search' && !action.payload.value);
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchTasks.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.data = payload?.content.map((el) => ({
          ...el,
          startDateTime: moment(el.startDateTime).format(dateFormatTime.dateTime),
          endDateTime: moment(el.endDateTime).format(dateFormatTime.dateTime)
        }));
      })
      .addCase(fetchTasks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTasks.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      .addCase(createTask.fulfilled, (state, { payload }) => {
        state.data = [payload, ...state.data];
        state.isLoading = false;
        state.totalElements += 1;
      })
      .addCase(createTask.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createTask.rejected, (state) => {
        state.isLoading = false;
      })
      //create tasks with dialog
      .addCase(saveTasks.fulfilled, (state, action) => {
        const newItems = action.payload.map((el) => ({
          ...el,
          startDateTime: moment(el.startDateTime).format(dateFormatTime.dateTime),
          endDateTime: moment(el.endDateTime).format(dateFormatTime.dateTime)
        }));
        state.isDetailsLoading = false;
        state.data = [...newItems, ...state.data];
        state.details = deepMerge(state.details, action.meta.arg);
        state.isPopupOpen = false;
        state.anchorEl = null;
        state.totalElements += newItems.length;
      })
      .addCase(saveTasks.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(saveTasks.rejected, (state) => {
        state.isDetailsLoading = false;
      })
      .addCase(deleteTasks.fulfilled, (state) => {
        state.selectionModel = [];
      });
  }
});
export const selectAnchorEl = (state) => state.ILT_SESSION_TASKS_SLICE.anchorEl;
export const selectIsPopupOpen = (state) => state.ILT_SESSION_TASKS_SLICE.isPopupOpen;

export const selectList = (state) => state.ILT_SESSION_TASKS_SLICE.data;
export const selectRows = (state) => state.ILT_SESSION_TASKS_SLICE.rows;
export const selectTotalElements = (state) => state.ILT_SESSION_TASKS_SLICE.totalElements;
export const selectTotalPages = (state) => state.ILT_SESSION_TASKS_SLICE.totalPages;
export const selectFilter = (state) => state.ILT_SESSION_TASKS_SLICE.filter;
export const selectIsLoading = (state) => state.ILT_SESSION_TASKS_SLICE.isLoading;
export const selectMode = (state) => state.ILT_SESSION_TASKS_SLICE.mode;
export const selectSelectionModel = (state) => state.ILT_SESSION_TASKS_SLICE.selectionModel;
const { actions, reducer } = iltSessionTasksTableSlice;
export const {
  setPopupIsClose,
  setPopupIsOpen,
  setData,
  setFilterParams,
  resetState,
  setSelectionModel,
  setSelectedItems
} = actions;

export default reducer;
