import moment from 'moment';

import { MUI_TABLE_FILTER_OPERATORS } from '@config/filterOperators';

import { isDateStringValid } from '../dates';

const getAdvancedFiltersForMUITable = (values = {}, onFilterReset = null) => {
  if (!values || !values?.items?.length) {
    return;
  }

  const { columnField, value, operatorValue } = values.items[0];
  if (!columnField || !operatorValue) {
    return;
  }

  const isValueOptional = ['isNotEmpty', 'isEmpty'].includes(operatorValue);
  if (!value && !isValueOptional) {
    onFilterReset && onFilterReset();
    return;
  }

  return isValueOptional
    ? [
        {
          key: columnField,
          operation: MUI_TABLE_FILTER_OPERATORS[operatorValue]
        }
      ]
    : [
        {
          key: columnField,
          operation: MUI_TABLE_FILTER_OPERATORS[operatorValue],
          value: isDateStringValid(value) ? moment(value).format('YYYY-MM-DD') : value
        }
      ];
};

export default getAdvancedFiltersForMUITable;
