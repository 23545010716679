import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useSearch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';

import { ACTION_MODES } from '@common/Constants';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import { saveState } from '@common/storage/persistSlice';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';
import { TABLE_OPTIONS } from '@config/network';
import {
  selectLocationDetails,
  selectFilter as selectFilterBp
} from '@pages/BusinessPartners/businessPartnersSlice';
import {
  selectList,
  fetchMeetingRooms,
  selectFilter,
  selectIsLoading,
  resetState,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  selectSelectionModel,
  setSelectionModel,
  getVenueId
} from '@pages/Locations/components/Tabs/MeetingRoomTable/meetingRoomTableSlice';
import { CreateMeetingRoom } from '@pages/MeetingRooms';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  content: {
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  },
  cellCheckbox: {
    padding: '5px 40px 5px 5px !important'
  }
}));

const MeetingRoomTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarOptions = []
}) => {
  const classes = useStyles();
  const search = useSearch();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const details = useSelector(selectLocationDetails);
  const { roles = [] } = details || {};
  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const filterBp = useSelector(selectFilterBp);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);

  useEffect(() => {
    const venueId = getVenueId(roles);
    if (venueId) {
      dispatch(fetchMeetingRooms({ entityId: venueId, filter: filter }));
    }
  }, [
    roles[0]?.venue?.id,
    roles[0].supplierType?.id,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.size
  ]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add':
        return !entityId || !entityType;
      case 'delete':
        return true; // selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onToolbarItemClick = (fieldName) => {
    switch (fieldName) {
      case 'add_sessions':
        dispatch(saveState({ selectedId: entityId, businessPartnerFilter: filterBp }));
        navigate({
          search: (previousUrlParams) => ({
            ...previousUrlParams,
            mode: ACTION_MODES.Create,
            venueId: entityId
          })
        });
        return;
      case 'delete':
        return;
      default:
        return;
    }
  };

  return (
    <>
      <div className={classes.root}>
        {search?.mode === ACTION_MODES.Create ? (
          <CreateMeetingRoom venue={details} />
        ) : (
          <TabContent>
            <div className={classes.toolbarContainer}>
              <Typography variant="h6" component="h6" color="primary">
                {Localize.get('Launchpad.MeetingRooms')}
              </Typography>
              <Toolbar className={classes.toolbar}>
                {toolbarOptions?.map((item, index) => (
                  <ToolbarItem
                    key={index}
                    item={item}
                    isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                    onToolbarItemClick={onToolbarItemClick}
                  />
                ))}
              </Toolbar>
            </div>
            <TabContainer>
              <DataGrid
                keepNonExistentRowsSelected={true}
                loading={isLoading}
                rows={data ?? []}
                columns={[
                  ...columns.map((column) => ({
                    ...column,
                    headerName: Localize.get(column.headerName),
                    renderCell: (params) => (
                      <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                        {['name', 'phone', 'floor'].includes(params.field) ? (
                          <Tooltip title={params.value} arrow>
                            <Typography
                              noWrap
                              variant="body"
                              component="div"
                              sx={{
                                maxWidth: params?.colDef?.width - 10 // 10 px for three dots
                              }}
                            >
                              {params.value}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography variant="body" component="div">
                            {params.value}
                          </Typography>
                        )}
                      </div>
                    )
                  }))
                ]}
                initialState={{
                  pagination: {
                    pageSize: totalPages,
                    rowCount: totalElements,
                    page: filter?.page
                  }
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  dispatch(setSelectionModel(newSelectionModel));
                }}
                selectionModel={selectionModel}
                checkboxSelection
                localeText={getLocalesText(Localize)}
                pagination
                paginationMode="server"
                disableColumnMenu
                page={filter?.page}
                pageSize={filter?.size}
                rowCount={totalElements}
                rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
                disableSelectionOnClick
                components={{
                  Toolbar: () => <TableToolbar />
                }}
                onPageChange={(page) => dispatch(setFilterParams({ page, selectionModel }))}
                onPageSizeChange={(value) =>
                  dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
                }
              />
            </TabContainer>
          </TabContent>
        )}
      </div>
    </>
  );
};

export default MeetingRoomTable;
