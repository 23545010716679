import Localize from 'react-intl-universal';

import { omit } from 'lodash';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { EXPORTS_PATHS, ILT_SESSION_PATHS } from '@common/network/ApiPaths';
import ClassNamesForExport from '@common/network/ClassNamesForExport';
import { SnackbarSeverityTypes, showSnackbar } from '@components/Snackbar/snackbarSlice';
import { TABLE_OPTIONS } from '@config/network';
import { getByPathAndParams } from '@services/BaseApi';

export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0],
    // Saved in filter object but not sent as url param
    sessionId: ''
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: [],
  exportAnchor: null,
  isExportPopupOpen: false,
  isExportLoading: false
};

export const SESSION_DAYS_SLICE = 'sessionDays';

export const fetchSessionDays = createAsyncThunk(
  `${SESSION_DAYS_SLICE}/getSessionDays`,
  ({ filter }) => {
    return getByPathAndParams({
      path: ILT_SESSION_PATHS.GET_SESSION_DAY,
      pathVariables: { id: filter.sessionId },
      params: omit(filter, 'sessionId')
    })
      .then((response) => response.data)
      .catch((error) => error);
  },
  {
    condition: ({ filter }) => Boolean(filter.sessionId)
  }
);

export const generateReport = createAsyncThunk(
  `${SESSION_DAYS_SLICE}/export`,
  ({ id, type }, { rejectWithValue, dispatch }) => {
    dispatch(setExportPopupClosed());

    return getByPathAndParams({
      path: EXPORTS_PATHS.GET.replace(':className', ClassNamesForExport.SESSION_DAY)
    }).then(({ data: fieldsToExport = [] }) => {
      return getByPathAndParams({
        path: ILT_SESSION_PATHS.EXPORT_SESSION_DAYS,
        pathVariables: { iltSessionId: id, fileType: type, fieldsToExport },
        responseType: 'blob'
      })
        .then((response) => {
          dispatch(
            showSnackbar({
              message: Localize.get('SuccessMessage.Download', {
                entity: Localize.get('IltSession.SessionDayList')
              }),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
          return response;
        })
        .catch((error) => {
          dispatch(
            showSnackbar({
              message: Localize.get('InitializeError.Download', {
                entity: Localize.get('IltSession.SessionDayList')
              }),
              severity: SnackbarSeverityTypes.ERROR
            })
          );
          return rejectWithValue(error);
        });
    });
  }
);

export const sessionDaysTableSlice = createSlice({
  name: SESSION_DAYS_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setExportPopupOpened: (state, { payload }) => {
      state.exportAnchor = payload;
      state.isExportPopupOpen = true;
    },
    setExportPopupClosed: (state) => {
      state.exportAnchor = null;
      state.isExportPopupOpen = false;
    },
    setSelectionModel: (state, { payload }) => {
      state.selectionModel = payload;
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = {
        ...state.filter,
        ...newFilterValues
      };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchSessionDays.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = payload?.totalElements || state.totalElements;
        state.data = payload?.content || [];
      })
      .addCase(fetchSessionDays.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSessionDays.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      .addCase(generateReport.fulfilled, (state, { payload }) => {
        let [, filename] = payload.headers['content-disposition'].split('filename=');
        filename = filename.replaceAll('"', '');

        const link = document.createElement('a');
        document.body.appendChild(link);
        link.style = 'display: none';
        link.setAttribute('download', filename);
        const blob = new Blob([payload.data]);
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        state.isExportLoading = false;
      })
      .addCase(generateReport.pending, (state) => {
        state.isExportLoading = true;
      })
      .addCase(generateReport.rejected, (state) => {
        state.isExportLoading = false;
      });
  }
});

export const selectList = (state) => state.SESSION_DAYS_SLICE.data;
export const selectTotalElements = (state) => state.SESSION_DAYS_SLICE.totalElements;
export const selectTotalPages = (state) => state.SESSION_DAYS_SLICE.totalPages;
export const selectFilter = (state) => state.SESSION_DAYS_SLICE.filter;
export const selectIsLoading = (state) => state.SESSION_DAYS_SLICE.isLoading;
export const selectSelectionModel = (state) => state.SESSION_DAYS_SLICE.selectionModel;
export const selectExportAnchor = (state) => state.SESSION_DAYS_SLICE.exportAnchor;
export const selectIsExportPopupOpen = (state) => state.SESSION_DAYS_SLICE.isExportPopupOpen;
export const selectIsExportLoading = (state) => state.SESSION_DAYS_SLICE.isExportLoading;

const { actions, reducer } = sessionDaysTableSlice;

export const {
  setData,
  setFilterParams,
  resetState,
  setSelectionModel,
  setExportPopupOpened,
  setExportPopupClosed
} = actions;

export default reducer;
