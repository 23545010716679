import React from 'react';
import Localize from 'react-intl-universal';

import { Field } from 'formik';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

import FormArray from '@components/FormArray';
import FormContainer from '@components/FormContainer';
import FormGroupName from '@components/FormGroupName';
import FormComponentsMap from '@config/FormComponentsMap';

const CreateForm = ({
  fieldsConfig = [],
  values = {},
  mapPropsToAddress = null,
  formContainerSX = {},
  gridItemSX = {},
  formGroupNameSX = {}
}) => (
  <FormContainer formContainerSX={formContainerSX}>
    {fieldsConfig.map(
      ({ groupName, isVisible = true, fields = [], arrayName, arrayConfig }, index) =>
        isVisible ? (
          <Grid container spacing={{ xs: 0, md: 0 }} key={index}>
            <Grid item xs={12}>
              <FormGroupName
                groupName={Localize.get(groupName)}
                formGroupNameSX={formGroupNameSX}
              />
            </Grid>

            {arrayName ? (
              <FormArray
                arrayName={arrayName}
                values={values}
                fields={fields}
                mapPropsToAddress={mapPropsToAddress}
                context="create"
                arrayConfig={arrayConfig}
              />
            ) : (
              <Grid container spacing={{ xs: 2, md: 3 }}>
                {fields?.map(
                  (
                    { component, label, column = 6, alignItems = null, isVisible = true, ...rest },
                    fieldIndex
                  ) => (
                    <Grid
                      key={`${index}-${fieldIndex}`}
                      item
                      xs={12}
                      sm={column}
                      sx={{ mb: 3, ...gridItemSX }}
                    >
                      {isVisible && (
                        <FormControl fullWidth size="small" sx={{ alignItems: `${alignItems}` }}>
                          <Field
                            {...rest}
                            label={Localize.get(label)}
                            component={FormComponentsMap[component]}
                          />
                        </FormControl>
                      )}
                    </Grid>
                  )
                )}
              </Grid>
            )}
          </Grid>
        ) : null
    )}
  </FormContainer>
);

export default CreateForm;
