import Localize from 'react-intl-universal';

import * as yup from 'yup';

let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

export default () => {
  return yup.object().shape({
    hotel: yup.object().shape({
      id: yup.number().nullable(true).required(Localize.get('ValidationMessages.Hotel'))
    }),
    accommodation: yup.object().shape({
      id: yup.number().nullable(true).required(Localize.get('ValidationMessages.RoomRequired'))
    }),
    contingent: yup.string().required(Localize.get('ValidationMessages.ContingentAmount')),
    price: yup
      .number()
      .required(Localize.get('ValidationMessages.PricePpNight'))
      .min(0, Localize.get('ValidationMessages.PositiveNumber'))
      .test('is-decimal', Localize.get('ValidationMessages.DecimalAllowed'), (val) => {
        if (val != undefined) {
          return patternTwoDigisAfterComma.test(val);
        }
        return true;
      }),
    startDate: yup
      .date()
      .required(Localize.get('ValidationMessages.StartDate'))
      .typeError(Localize.get('ValidationMessages.DateTimeInvalid')),
    endDate: yup
      .date()
      .min(yup.ref('startDate'), Localize.get('ValidationMessages.EndDateStartDate'))
      .typeError(Localize.get('ValidationMessages.DateTimeInvalid'))
      .required(Localize.get('ValidationMessages.EndDate')),
    validUntil: yup
      .date()
      .max(yup.ref('startDate'), Localize.get('ValidationMessages.ValidUntilBeforeStartDate'))
      .nullable()
  });
};
