import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useSearch, useMatch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import { omit } from 'lodash';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { ACTION_MODES, SORTING_ORDER } from '@common/Constants';
import { checkChangedFields } from '@common/helpers/helpers';
import getAdvancedFiltersForMUITable from '@common/helpers/tables/getAdvancedFiltersForMUITable';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import EntityTypes from '@common/network/EntityTypes';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import {
  closeSidebar,
  openSidebar,
  setMode as setRightSidebarMode
} from '@components/RightSidebar/rightSidebarSlice';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import { getFilterOperatorsBasedOnType } from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';
import {
  fetchPrices,
  resetState,
  selectFilter,
  selectIsLoading,
  selectList,
  selectSelectionModel,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  setSelectionModel,
  deletePrices,
  selectDetails,
  setDetails,
  patchPrices
} from '@pages/IltSession/components/Tabs/Price/priceTableSlice';

import CreatePrice from './CreatePrice/CreatePrice';
import { pricesSchema } from './CreatePrice/pricesSchema';
import sidebarConfig from './sidebarConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  }
}));

const PriceTable = ({ columns = [], entityId = null, entityType = null, toolbarOptions = [] }) => {
  const {
    data: {
      currencyType: { data: currencyType }
    }
  } = useMatch();

  const classes = useStyles();
  const dispatch = useDispatch();
  const search = useSearch();
  const navigate = useNavigate();
  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);
  const details = useSelector(selectDetails);

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(fetchPrices({ entityId: entityId, filter: filter }));
    }
  }, [
    entityId,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.size,
    filter?.filters
  ]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add_price':
        return !entityId || !entityType || data?.length === 2;
      case 'delete':
        return selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onToolbarItemClick = (fieldName) => {
    switch (fieldName) {
      case 'add_price':
        navigate({
          search: (previousUrlParams) => ({
            ...previousUrlParams,
            mode: ACTION_MODES.Create
          })
        });
        break;

      case 'delete':
        {
          dispatch(
            openConfirmDialog({
              title: Localize.get('ConfirmationMessages.Delete', {
                item: Localize.get('Events.SingularOrPluralPrice', {
                  selectionModel: selectionModel.length
                })?.toLowerCase()
              }),
              confirmButton: Localize.get('Buttons.Delete'),
              cancelButton: Localize.get('Buttons.Cancel')
            })
          )
            .unwrap()
            .then((result) => {
              if (result === CONFIRM_ACTIONS.Confirm) {
                dispatch(deletePrices({ priceIds: selectionModel, id: entityId }));
              }
            });
        }
        break;

      default:
        return false;
    }
  };

  const extendedConfig = (setFieldValue) => {
    return {
      ['currency.id']: {
        options: currencyType,
        onChange: (event) => {
          setFieldValue('currency', {
            id: event.target.value,
            value: currencyType.find((s) => s.id === event.target.value).value
          });
        }
      },
      ['validTo']: {
        onChange: (value) => setFieldValue('validTo', value)
      },
      ['validFrom']: {
        onChange: (value) => setFieldValue('validFrom', value)
      }
    };
  };

  useEffect(() => {
    if (!details) {
      return;
    }

    dispatch(
      openSidebar({
        config: {
          title: Localize.get('ResourcesTile.PriceDetails'),
          fieldsConfig: sidebarConfig
        },
        data: details,
        extendedConfig: extendedConfig,
        validationSchema: pricesSchema,
        onSave: (values) => {
          return dispatch(
            patchPrices({
              data: checkChangedFields(details, values),
              id: details.id,
              iltSessionId: entityId
            })
          )
            .unwrap()
            .then(() => {
              dispatch(setRightSidebarMode('read'));
              return Promise.resolve();
            })
            .catch((rejectedValueOrSerializedError) => {
              return Promise.reject({
                rejectedValueOrSerializedError,
                entityType: EntityTypes.PRICES
              });
            });
        },
        onDelete: () => {
          dispatch(
            openConfirmDialog({
              title: Localize.get('ConfirmationMessages.Delete', {
                item: Localize.get('ResourcesTile.Price')?.toLowerCase()
              }),
              confirmButton: Localize.get('Buttons.Delete'),
              cancelButton: Localize.get('Buttons.Cancel')
            })
          )
            .unwrap()
            .then((result) => {
              if (result === CONFIRM_ACTIONS.Confirm) {
                dispatch(deletePrices({ priceIds: [details.id], id: entityId }));
                dispatch(setRightSidebarMode('read'));
                dispatch(closeSidebar());
              }
            });
        }
      })
    );
  }, [details]);

  const onRowClick = ({ row }) => dispatch(setDetails(row));

  const onFilterChange = (values) => {
    const advancedFilters = getAdvancedFiltersForMUITable(values, () =>
      dispatch(fetchPrices({ entityId: entityId, filter: { ...omit(filter, 'filters') } }))
    );

    dispatch(setFilterParams({ key: 'filters', value: { advancedFilters } }));
  };

  const onSortChange = (sortModel) =>
    dispatch(
      setFilterParams([
        { key: 'sortBy', value: sortModel[0].field },
        { key: 'sortDirection', value: sortModel[0].sort }
      ])
    );

  return (
    <div className={classes.root}>
      {search?.mode === ACTION_MODES.Create ? (
        <CreatePrice />
      ) : (
        <TabContent>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('Labels.Prices')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              {toolbarOptions?.map((item, index) => (
                <ToolbarItem
                  key={index}
                  item={item}
                  isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                  onToolbarItemClick={onToolbarItemClick}
                />
              ))}
            </Toolbar>
          </div>
          <TabContainer>
            <StyledDataGrid
              onRowClick={onRowClick}
              keepNonExistentRowsSelected
              loading={isLoading}
              rows={data}
              columns={[
                ...columns.map((column) => ({
                  ...column,
                  headerName: Localize.get(column.headerName),
                  filterOperators: getFilterOperatorsBasedOnType(column?.type),
                  renderCell: (params) => (
                    <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                      {['typeId'].includes(params.field) ? (
                        <Tooltip title={params.value} arrow>
                          <Typography
                            noWrap
                            variant="body"
                            component="div"
                            sx={{
                              maxWidth: params?.colDef?.width - 10 // 10 px for three dots
                            }}
                          >
                            {params.value}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography variant="body" component="div">
                          {params.value}
                        </Typography>
                      )}
                    </div>
                  )
                }))
              ]}
              initialState={{
                pagination: {
                  pageSize: totalPages,
                  rowCount: totalElements,
                  page: filter?.page
                }
              }}
              onSelectionModelChange={(newSelectionModel) => {
                dispatch(setSelectionModel(newSelectionModel));
              }}
              selectionModel={selectionModel}
              checkboxSelection
              localeText={getLocalesText(Localize)}
              pagination
              paginationMode="server"
              filterMode="server"
              disableColumnMenu
              page={filter?.page}
              pageSize={filter?.size}
              rowCount={totalElements}
              rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
              disableSelectionOnClick
              components={{ Toolbar: TableToolbar }}
              onPageChange={(page) => dispatch(setFilterParams({ page, selectionModel }))}
              onPageSizeChange={(value) =>
                dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
              }
              sortingMode="server"
              sortingOrder={SORTING_ORDER}
              onSortModelChange={onSortChange}
              onFilterModelChange={onFilterChange}
            />
          </TabContainer>
        </TabContent>
      )}
    </div>
  );
};

export default PriceTable;
