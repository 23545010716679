import { useRef } from 'react';

import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { styled } from '@mui/material/styles';

const Input = styled('input')({
  display: 'none'
});

const ToolbarItem = ({ item, index, isDisabled, onToolbarItemClick }) => {
  const uploadRef = useRef();
  const components = {
    add: (
      <label key={index} htmlFor="icon-button-file">
        <Input
          ref={uploadRef}
          disabled={isDisabled}
          onChange={(e) => {
            onToolbarItemClick(item.fieldName, e.target.files);

            // Resets input value so onChange can retrigger when adding same attachment.
            if (uploadRef.current) {
              uploadRef.current.value = null;
            }
          }}
          accept="image/*"
          id="icon-button-file"
          type="file"
        />
        <Button
          data-test-id="upload"
          sx={{ mr: 1 }}
          variant="outlined"
          aria-label="upload picture"
          disabled={isDisabled}
          component="span"
        >
          <Icon>{item.icon}</Icon>
        </Button>
      </label>
    ),
    delete: (
      <Button
        data-test-id="delete-tabs"
        sx={{ mr: 1 }}
        variant="outlined"
        color="error"
        aria-label="delete"
        key={index}
        disabled={isDisabled}
        onClick={(event) => onToolbarItemClick(item.fieldName, event)}
      >
        <Icon>{item.icon}</Icon>
      </Button>
    ),
    add_attachment: (
      <Button
        data-test-id="add-attachment"
        sx={{ mr: 1 }}
        variant="outlined"
        disabled={isDisabled}
        aria-label="add attachment"
        key={index}
        onClick={() => onToolbarItemClick(item.fieldName)}
      >
        <Icon>{item.icon}</Icon>
      </Button>
    ),
    add_note: (
      <Button
        data-test-id="add-note"
        sx={{ mr: 1 }}
        variant="outlined"
        disabled={isDisabled}
        aria-label="add"
        key={index}
        onClick={(event) => onToolbarItemClick(item.fieldName, event)}
      >
        <Icon>{item.icon}</Icon>
      </Button>
    ),
    export: (
      <Button
        data-test-id="export"
        sx={{ mr: 1 }}
        variant="outlined"
        disabled={isDisabled}
        aria-label="add"
        key={index}
        onClick={(event) => onToolbarItemClick(item.fieldName, event)}
      >
        <Icon>{item.icon}</Icon>
      </Button>
    ),
    email_participant: (
      <Button
        data-test-id="add-note"
        sx={{ mr: 1 }}
        variant="outlined"
        disabled={isDisabled}
        aria-label="add"
        key={index}
        onClick={(event) => onToolbarItemClick(item.fieldName, event)}
      >
        <Icon>{item.icon}</Icon>
      </Button>
    ),
    add_sessions: (
      <Button
        data-test-id="add-session"
        sx={{ mr: 1 }}
        variant="outlined"
        disabled={isDisabled}
        aria-label="add"
        key={index}
        onClick={(event) => onToolbarItemClick(item.fieldName, event)}
      >
        <Icon>{item.icon}</Icon>
      </Button>
    ),
    add_accommodation: (
      <Button
        data-test-id="add_accommodation"
        sx={{ mr: 1 }}
        variant="outlined"
        disabled={isDisabled}
        aria-label="add"
        key={index}
        onClick={(event) => onToolbarItemClick(item.fieldName, event)}
      >
        <Icon>{item.icon}</Icon>
      </Button>
    ),
    cancel_add_accommodation: (
      <Button
        data-test-id="delete-tabs"
        sx={{ mr: 1 }}
        variant="outlined"
        color="error"
        aria-label="delete"
        key={index}
        disabled={isDisabled}
        onClick={(event) => onToolbarItemClick(item.fieldName, event)}
      >
        <Icon>{item.icon}</Icon>
      </Button>
    ),
    add_price: (
      <Button
        data-test-id="add_price"
        sx={{ mr: 1 }}
        variant="outlined"
        disabled={isDisabled}
        aria-label="add"
        key={index}
        onClick={(event) => onToolbarItemClick(item.fieldName, event)}
      >
        <Icon>{item.icon}</Icon>
      </Button>
    ),
    cancel_add_price: (
      <Button
        data-test-id="delete-tabs"
        sx={{ mr: 1 }}
        variant="outlined"
        color="error"
        aria-label="delete"
        key={index}
        disabled={isDisabled}
        onClick={(event) => onToolbarItemClick(item.fieldName, event)}
      >
        <Icon>{item.icon}</Icon>
      </Button>
    ),
    save_prices: (
      <Button
        data-test-id="save_prices"
        sx={{ mr: 1 }}
        variant="outlined"
        disabled={isDisabled}
        aria-label="save_prices"
        key={index}
        onClick={(event) => onToolbarItemClick(item.fieldName, event)}
      >
        <Icon>{item.icon}</Icon>
      </Button>
    ),
    add_tasks: (
      <Button
        data-test-id="add_tasks"
        sx={{ mr: 1 }}
        variant="outlined"
        disabled={isDisabled}
        aria-label="add"
        key={index}
        onClick={(event) => onToolbarItemClick(item.fieldName, event)}
      >
        <Icon>{item.icon}</Icon>
      </Button>
    ),
    add_participant: (
      <Button
        data-test-id="add-participant"
        sx={{ mr: 1 }}
        variant="outlined"
        disabled={isDisabled}
        aria-label="add"
        key={index}
        onClick={(event) => onToolbarItemClick(item.fieldName, event)}
      >
        <Icon>{item.icon}</Icon>
      </Button>
    ),
    edit: (
      <Button
        data-test-id="edit"
        sx={{ mr: 1 }}
        variant="outlined"
        disabled={isDisabled}
        aria-label="edit"
        key={index}
        onClick={(event) => onToolbarItemClick(item.fieldName, event)}
      >
        <Icon>{item.icon}</Icon>
      </Button>
    ),
    save_accommodation: (
      <Button
        data-test-id="save_accommodation"
        sx={{ mr: 1 }}
        variant="outlined"
        disabled={isDisabled}
        aria-label="save_accommodation"
        key={index}
        onClick={(event) => onToolbarItemClick(item.fieldName, event)}
      >
        <Icon>{item.icon}</Icon>
      </Button>
    ),
    default: <DisabledByDefaultIcon key={index} color="error" />
  };

  return components[item.fieldName] || components['default'];
};

export default ToolbarItem;
