import Localize from 'react-intl-universal';

import * as yup from 'yup';

// Define the allowed MIME types
const allowedTypes = [
  'image/',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

export default () => {
  return yup.object().shape({
    displayName: yup
      .string()
      .required(Localize.get('ValidationMessages.NameRequired'))
      .max(100, Localize.get('Labels.FileNameLong')),
    visibleToAdministrators: yup.boolean(),
    visibleToTrainers: yup.boolean(),
    visibleToParticipants: yup.boolean(),
    visibleToLocationResponsible: yup.boolean(),
    file: yup
      .mixed()
      .required(Localize.get('IltSession.FileRequired'))
      .test('fileSize', Localize.get('Tables.Error'), (value) => {
        // So far all sizes are allowed, will be changed in future tasks
        return !!value;
      })
      .test('fileType', Localize.get('IltSession.InvalidFileType'), (value) => {
        if (!value) {
          return false; // No file selected, no validation needed
        }

        return allowedTypes.some((allowedType) => value.type.startsWith(allowedType));
      })
      .nullable()
  });
};
