import React from 'react';

import { Box, Typography } from '@mui/material';

const DescriptionComponent = ({ label = '' }) => {
  return (
    <Box sx={{ marginTop: '0.5rem' }}>
      <Typography component="label">{label}</Typography>
    </Box>
  );
};

export default DescriptionComponent;
