import React from 'react';

import { BUSINESS_PARTNER_TYPE } from '@common/Constants';
import NoData from '@components/NoData';
import Tabs from '@components/Tabs';
import tabsConfig, {
  BUSINESS_PARTNERS_TABS_COMPONENTS,
  accountTabs,
  individualCustomerTabs
} from '@pages/BusinessPartners/components/Tabs/tabsConfig';

const BusinessPartnersTabs = ({
  entityId = '',
  entityType = '',
  details = null,
  setDetails = () => {}
}) => {
  const isAccount = details?.businessPartnerType?.id === BUSINESS_PARTNER_TYPE.ACCOUNT;
  const isIndividualCustomer =
    details?.businessPartnerType?.id === BUSINESS_PARTNER_TYPE.INDIVIDUAL_CUSTOMER;

  return (
    <Tabs
      tabsConfig={
        isAccount ? accountTabs : isIndividualCustomer ? individualCustomerTabs : tabsConfig
      }
      count={details?.counts}
      renderContent={(tab) => {
        const DynamicComponent = BUSINESS_PARTNERS_TABS_COMPONENTS[tab?.key];
        return DynamicComponent ? (
          <DynamicComponent
            entityId={entityId}
            entityType={entityType}
            columns={tab.columns}
            toolbarOptions={tab.options}
            allowedEntityId={8}
            setDetails={setDetails}
            key={entityId}
          />
        ) : (
          <NoData />
        );
      }}
    ></Tabs>
  );
};

export default BusinessPartnersTabs;
