import React, { useCallback } from 'react';
import Localize from 'react-intl-universal';

import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';

import getLocalesText from '@common/helpers/tables/getLocalesText';
import TableToolbar from '@components/TableToolbar';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import { containsOnlyDialogFilters, equalNumbersDialogFilters } from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';

import useDialogSearch from '../utils/useDialogSearch';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  content: {
    position: 'relative',
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5)
  },
  container: {
    height: 400,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  }
}));

const DialogDataGrid = ({
  fetchDataPath = '/',
  initQueryState,
  columns,
  extendedComponents,
  getRowId = (params) => params?.id ?? undefined,
  onSelectChange,
  selectedItems
}) => {
  const classes = useStyles();
  const {
    data = [],
    loadingData,
    pagination,
    setActivePage,
    itemHandler,
    activePage,
    changePageSize,
    pageSize,
    queryState
  } = useDialogSearch(fetchDataPath, initQueryState);

  const onSelectionModelChange = useCallback(
    (newValues) => onSelectChange(newValues, data),
    [selectedItems, data]
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.content}>
        <div className={classes.container}>
          <StyledDataGrid
            checkboxSelection
            localeText={getLocalesText(Localize)}
            loading={loadingData}
            rows={data ? data : []}
            getRowId={getRowId}
            columns={[
              ...columns.map((col) => {
                return {
                  ...col,
                  headerName: Localize.get(col.headerName),
                  filterOperators:
                    col?.type === 'number' ? equalNumbersDialogFilters : containsOnlyDialogFilters
                };
              })
            ]}
            getRowClassName={() => classes.tableRow}
            // selectionModel={selectionModel}
            components={{ Toolbar: TableToolbar }}
            componentsProps={{
              toolbar: {
                queryState,
                itemHandler,
                extendedComponents
              }
            }}
            // disableMultipleSelection={false}
            onSelectionModelChange={onSelectionModelChange}
            selectionModel={selectedItems.map(({ id }) => id)}
            keepNonExistentRowsSelected
            pagination
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            page={activePage}
            pageSize={pageSize}
            rowCount={pagination?.totalElements || 0}
            disableSelectionOnClick={true}
            rowsPerPageOptions={TABLE_OPTIONS.DIALOG_PAGE_SIZE_OPTIONS}
            onPageChange={setActivePage}
            onPageSizeChange={changePageSize}
            onFilterModelChange={itemHandler}
          />
        </div>
      </Paper>
    </div>
  );
};

export default DialogDataGrid;
