const FILTER_KEYS = {
  Contains: 'contains',
  Equals: 'equal',
  NotEqual: 'not_equal',
  StartsWith: 'starts_with',
  EndsWith: 'ends_with',
  IsEmpty: 'is_null',
  Is: 'is',
  IsNot: 'is_not',
  IsAfter: 'is_after',
  IsOnOrAfter: 'is_on_or_after',
  IsBefore: 'is_before',
  IsOnOrBefore: 'is_on_or_before',
  IsNotEmpty: 'is_not_null',
  GreaterThen: 'greater_than',
  GreaterThenOrEqual: 'greater_than_equal',
  LessThen: 'less_than',
  LessThenOrEqual: 'less_than_equal',
  IsNotOn: 'is_not_on',
  IsOn: 'is_on'
};

const BOOLEAN_OPERATORS = [
  { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
  { key: FILTER_KEYS.IsNot, label: 'Tables.FilterValues.IsNot' }
];

const SELECT_OPERATORS = [
  { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' },
  { key: FILTER_KEYS.NotEqual, label: 'Tables.FilterValues.NotEqual' },
  { key: FILTER_KEYS.IsEmpty, label: 'Tables.FilterValues.IsEmpty' },
  { key: FILTER_KEYS.IsNotEmpty, label: 'Tables.FilterValues.IsNotEmpty' }
];

const STRING_OPERATORS = [
  { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' },
  { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' },
  { key: FILTER_KEYS.NotEqual, label: 'Tables.FilterValues.NotEqual' },
  { key: FILTER_KEYS.StartsWith, label: 'Tables.FilterValues.StartsWith' },
  { key: FILTER_KEYS.EndsWith, label: 'Tables.FilterValues.EndsWith' },
  { key: FILTER_KEYS.IsEmpty, label: 'Tables.FilterValues.IsEmpty' },
  { key: FILTER_KEYS.IsNotEmpty, label: 'Tables.FilterValues.IsNotEmpty' }
];

const NUMBER_OPERATORS = [
  { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' },
  { key: FILTER_KEYS.GreaterThen, label: 'Tables.FilterValues.GreaterThen' },
  { key: FILTER_KEYS.GreaterThenOrEqual, label: 'Tables.FilterValues.GreaterThenOrEqual' },
  { key: FILTER_KEYS.LessThen, label: 'Tables.FilterValues.LessThen' },
  { key: FILTER_KEYS.LessThenOrEqual, label: 'Tables.FilterValues.LessThenOrEqual' },
  { key: FILTER_KEYS.IsEmpty, label: 'Tables.FilterValues.IsEmpty' },
  { key: FILTER_KEYS.IsNotEmpty, label: 'Tables.FilterValues.IsNotEmpty' }
];

const DATE_OPERATORS = [
  { key: FILTER_KEYS.IsNot, label: 'Tables.FilterValues.IsNot' },
  { key: FILTER_KEYS.IsAfter, label: 'Tables.FilterValues.IsAfter' },
  { key: FILTER_KEYS.IsOnOrAfter, label: 'Tables.FilterValues.IsOnOrAfter' },
  { key: FILTER_KEYS.IsBefore, label: 'Tables.FilterValues.IsBefore' },
  { key: FILTER_KEYS.IsOnOrBefore, label: 'Tables.FilterValues.IsOnOrBefore' },
  { key: FILTER_KEYS.IsEmpty, label: 'Tables.FilterValues.IsEmpty' },
  { key: FILTER_KEYS.IsNotEmpty, label: 'Tables.FilterValues.IsNotEmpty' },
  { key: FILTER_KEYS.IsOn, label: 'Tables.FilterValues.IsOn' }
];

const FILTER_OPERATORS = {
  string: STRING_OPERATORS,
  boolean: BOOLEAN_OPERATORS,
  select: SELECT_OPERATORS,
  rating: NUMBER_OPERATORS,
  number: NUMBER_OPERATORS,
  date: DATE_OPERATORS
};

const FILTER_TYPES = {
  string: 'string',
  boolean: 'boolean',
  select: 'select',
  rating: 'rating',
  number: 'number',
  date: 'date'
};

const LOGICAL_OPERATORS = [
  { key: 'AND', label: 'Labels.And' },
  { key: 'OR', label: 'Labels.Or' }
];

export { FILTER_OPERATORS, LOGICAL_OPERATORS, FILTER_KEYS, FILTER_TYPES };
