import React from 'react';

import NoData from '@components/NoData';
import Tabs from '@components/Tabs';
import tabsConfigClassroom, {
  ILT_SESSION_TABS_COMPONENTS
} from '@pages/IltSession/components/Tabs/tabsConfigClassroom';
import tabsConfigWebinar from '@pages/IltSession/components/Tabs/tabsConfigWebinar';

const IltSessionTabs = ({
  entityId = '',
  entityType = '',
  details = null,
  setDetails = () => {}
}) => {
  const currentConfig = details?.type?.id === 16 ? tabsConfigClassroom : tabsConfigWebinar;
  return (
    <Tabs
      variant="scrollable"
      tabsConfig={currentConfig}
      count={details?.counts}
      renderContent={(tab) => {
        const DynamicComponent = ILT_SESSION_TABS_COMPONENTS[tab?.key];
        return DynamicComponent ? (
          <DynamicComponent
            entityId={entityId}
            entityType={entityType}
            columns={tab.columns}
            toolbarOptions={tab.options}
            allowedEntityId={8}
            setDetails={setDetails}
            subColumns={tab?.subColumns}
            count={details?.counts}
            key={entityId}
          />
        ) : (
          <NoData />
        );
      }}
    ></Tabs>
  );
};

export default IltSessionTabs;
