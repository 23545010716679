/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import Localize from 'react-intl-universal';
import { Link, useNavigate, useSearch } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { omit } from 'lodash';

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import LaunchIcon from '@mui/icons-material/Launch';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/styles';

import avatar from '@assets/images/avatar.jpeg';
import logo from '@assets/images/contrimo-logo.png';
// eslint-disable-next-line no-unused-vars
import { ACTION_MODES } from '@common/Constants';
import { LOGIN_PATH } from '@common/network/ApiPaths';
import { useAppStyles } from '@navigation/AppRouter';
import { loginPath, rootPath } from '@navigation/routes/Routes';
import { logOut, selectIsAuthEnabled } from '@pages/Login/loginSlice';

import { toggleColorMode } from './navbarSlice';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: `${theme.sizes.appHeader} !important`,
  height: `${theme.sizes.appHeader} !important`,
  maxHeight: `${theme.sizes.appHeader} !important`
}));

const StyledButton = styled(Button)({
  textTransform: 'none',
  padding: '0',
  '&:hover': { background: 'transparent' }
});

const NavBar = ({ hideSideBar, setHideSideBar }) => {
  // eslint-disable-next-line no-unused-vars
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthEnabled = useSelector(selectIsAuthEnabled);
  const search = useSearch();
  const appStyles = useAppStyles({ isBlur: search?.mode === ACTION_MODES.Create });

  const MENU_BUTTONS = useMemo(
    () => [
      { id: 0, name: Localize.get('Launchpad.PersonalizeHomePage') },
      { id: 1, name: Localize.get('Launchpad.HideGroups') },
      { id: 2, name: Localize.get('Launchpad.ContactSupport') },
      { id: 3, name: Localize.get('Launchpad.GiveFeedback') },
      {
        id: 4,
        name: Localize.get('Launchpad.LogOut'),
        onClick: () => {
          dispatch(logOut())
            .unwrap()
            .then(() => navigate({ to: loginPath }))
            .catch((rejectedValueOrSerializedError) =>
              console.error(rejectedValueOrSerializedError)
            );
        }
      }
    ],
    []
  );

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  // eslint-disable-next-line no-unused-vars
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    setAnchorElNav(null);
  };

  const openInMea = (e) => {
    e.preventDefault();

    // if (!isAuthEnabled) {
    //   window.open(`${process.env.REACT_APP_MEA_URL}`, '_blank').focus();
    //   return;
    // }

    const auth = window.localStorage.getItem('auth');
    const authRoles = window.localStorage.getItem('roles');
    const authEmail = window.localStorage.getItem('email');
    const token = JSON.parse(auth).token;
    const roles = JSON.parse(authRoles).roles;
    const email = JSON.parse(authEmail).email;

    // window.open(
    //   `${process.env.REACT_APP_MEA_URL}tem?email=${email}&token=${token}&roles=${roles}`,
    //   '_self'
    // );
    window.open(
      `${process.env.REACT_APP_MEA_URL}tem?email=${email}&token=${token}&roles=${roles.join(',')}`,
      '_self'
    );
    return;

    // postByPathAndData({
    //   path: LOGIN_PATH.CREATE_TEMP_TOKEN
    // }).then(({ data }) => {
    //   console.log('data', data);

    //   // window.open(`${process.env.REACT_APP_MEA_URL}?tempToken=${data}`, '_blank');

    //   axios
    //     .post(
    //       // 'http://localhost:8080/api/v1/user/check-temp-token',
    //       'https://api.auth.tem.testcontrimo.com/api/v1/user/check-temp-token',

    //       {},
    //       { headers: { Authorization: `Bearer ${data}` } }
    //     )
    //     .then(({ data }) => {
    //       console.log('data', data);
    //       window.open(
    //         `http://auth.mea.testcontrimo.com/tem?email=${data.user.email}&roles=${data.user.roles}`,
    //         '_self'
    //       );
    //     });
    // });
  };

  return (
    <Box sx={{ flexGrow: 1 }} className={appStyles.blur}>
      <AppBar position="static" sx={{ boxShadow: 0 }}>
        <StyledToolbar>
          <IconButton
            data-test-id="hide-side-bar"
            onClick={() => setHideSideBar(!hideSideBar)}
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            disableRipple
          >
            <MenuIcon />
          </IconButton>

          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              navigate({
                to: rootPath,
                search: (previous) => ({ ...omit(previous, 'mode', 'activeTab') })
              })
            }
          >
            <img alt="Contrimo Logo" src={logo} />
          </Box>

          <Box sx={{ flexGrow: 1 }}></Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={Localize.get('Links.OpenMea')}>
              <IconButton
                data-test-id="open-mea"
                disableRipple
                sx={{ mr: 2 }}
                onClick={openInMea}
                color="inherit"
              >
                <LaunchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={` ${Localize.get('Labels.ToggleDarkMode')} ${
                theme.palette.mode === 'dark'
                  ? Localize.get('Labels.Off')
                  : Localize.get('Labels.On')
              }`}
            >
              <IconButton
                data-test-id="toggle-color-mode"
                disableRipple
                sx={{ mr: 2 }}
                onClick={() => dispatch(toggleColorMode())}
                color="inherit"
              >
                {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Tooltip>

            <Tooltip title={Localize.get('Labels.OpenSetting')}>
              <IconButton data-test-id="open-settings" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar sx={{ width: 32, height: 32 }} alt="Remy Sharp" src={avatar} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '1.75rem' }}
              data-test-id="navbar-menu"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClick={handleCloseUserMenu}
            >
              {MENU_BUTTONS.map(({ id, name, onClick }) => (
                <MenuItem
                  key={id}
                  id={id}
                  onClick={() => {
                    onClick && onClick();
                  }}
                >
                  <StyledButton variant="text" id={id}>
                    {name}
                  </StyledButton>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </StyledToolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
