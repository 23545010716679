import { isNil } from 'lodash';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { HOTEL_PATHS } from '@common/network/ApiPaths';
import { TABLE_OPTIONS } from '@config/network';
import { getByPathAndParams } from '@services/BaseApi';

export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    // sortBy: SORT_DATA_HOTELS[0].name,
    // sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0]
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: []
};

export const LOCATION_HOTEL_BOOKINGS_SLICE = 'locationHotelBookings';

export const fetchHotelBookings = createAsyncThunk(
  `${LOCATION_HOTEL_BOOKINGS_SLICE}/hotelBookings`,
  (filterObj) => {
    return getByPathAndParams({
      path: HOTEL_PATHS.GET_CONTINGENT_BOOKINGS,
      pathVariables: { hotelId: filterObj.entityId },
      params: filterObj.filter
    })
      .then((response) => response.data)
      .catch((error) => error);
  }
);

export const iltSessionHotelBookingTableSlice = createSlice({
  name: LOCATION_HOTEL_BOOKINGS_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};
      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }
      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }
      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchHotelBookings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.data = payload.content
          ? payload.content.map((item) => ({
              ...item?.contingent,
              id: item?.id,
              hotelName: item?.contingent?.event?.name,
              roomType: item?.contingent?.accommodation?.roomType?.value,
              arrival: item?.contingent?.startDate,
              departure: item?.contingent?.endDate,
              contingent: item?.contingent?.contingent,
              companyName: item?.eventParticipant?.company,
              participantName:
                item?.eventParticipant?.firstName + ' ' + item?.eventParticipant?.lastName,
              isEvent: item?.contingent?.event?.isEvent
            }))
          : [];
      })
      .addCase(fetchHotelBookings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHotelBookings.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      });
  }
});

export const selectList = (state) => state.LOCATION_HOTEL_BOOKINGS_SLICE.data;
export const selectRows = (state) => state.LOCATION_HOTEL_BOOKINGS_SLICE.rows;
export const selectTotalElements = (state) => state.LOCATION_HOTEL_BOOKINGS_SLICE.totalElements;
export const selectTotalPages = (state) => state.LOCATION_HOTEL_BOOKINGS_SLICE.totalPages;
export const selectFilter = (state) => state.LOCATION_HOTEL_BOOKINGS_SLICE.filter;
export const selectIsLoading = (state) => state.LOCATION_HOTEL_BOOKINGS_SLICE.isLoading;
export const selectSelectionModel = (state) => state.LOCATION_HOTEL_BOOKINGS_SLICE.selectionModel;

const { actions, reducer } = iltSessionHotelBookingTableSlice;

export const { setData, setFilterParams, resetState, setSelectionModel } = actions;

export default reducer;
