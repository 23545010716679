import { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import { CircularProgress, Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';

import { dateInitFormats } from '@common/Constants';
import { DIALOG_PATHS } from '@common/network/ApiPaths';
import CustomNoDataOverlay from '@components/CustomNoDataOverlay';
import DragandropCalendar from '@components/DragAndDropCalendar/DragAndDropCalendar';
import {
  fetchData,
  fetchMeetingRoomsByVenueId,
  resetState,
  setDragAndDropDraggableItems,
  setDragAndDropIsDialogOpen
} from '@components/DragAndDropCalendar/dragAndDropDialogSlice';
import DragAndDropWithDialog from '@components/DragAndDropCalendar/DragAndDropWithDialog';
import { scrubFiltersForBE } from '@components/FilterDialog/filtersSlice';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import ToolbarItem from '@components/TableToolbarItem';
import {
  saveSessionDay,
  selectDetails,
  selectList,
  setData,
  setDetails
} from '@pages/IltSession/iltSessionSlice';
import getUpdatedSessionTime from '@pages/IltSession/util/getUpdatedSessionTime';

import { fetchSessionDays, selectIsLoading, setIsLoading } from './sessionRoomsSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  content: {
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  container: {
    height: 400,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  }
}));

const SessionRoomsTable = ({ entityId = null, entityType = null, toolbarOptions = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const iltSessionDetails = useSelector(selectDetails);
  const isLoading = useSelector(selectIsLoading);
  const iltSessions = useSelector(selectList);

  useEffect(() => {
    if (!iltSessionDetails?.venues?.length) {
      return;
    }

    dispatch(setIsLoading(true));
    dispatch(resetState());
    populateWithData();
  }, [entityId, JSON.stringify(iltSessionDetails?.venues?.map((v) => v.id))]);

  const mapItemsToDraggable = (content) =>
    content?.map((c) => ({
      ...c,
      name: c.name,
      id: c.id,
      predefinedStart: new Date(0, 0, 0, c.startTime.split(':')[0], c.startTime.split(':')[1]),
      predefinedEnd: new Date(0, 0, 0, c.endTime.split(':')[0], c.endTime.split(':')[1])
    }));

  const onToolbarItemClick = (fieldName) => {
    switch (fieldName) {
      case 'edit':
        dispatch(setDragAndDropIsDialogOpen(true));
        return;
      default:
        return false;
    }
  };

  useEffect(() => {
    return () => {
      // Cleans drag and drop slice
      dispatch(resetState());
    };
  }, []);

  const onDragAndDropDialogAction = (e = null, result = []) => {
    if (e?.target?.name !== 'save') {
      return;
    }

    dispatch(setIsLoading(true));
    dispatch(resetState());

    Promise.all(
      result.map((sessionDay) => {
        return dispatch(
          saveSessionDay({
            id: sessionDay.id,
            meetingRoomId: sessionDay?.resourceId,
            venueId: sessionDay?.venueId,
            dayDate: moment(sessionDay.start || sessionDay.predefinedStart).format(
              moment.HTML5_FMT.DATE
            ),
            startTime: moment(sessionDay.start || sessionDay.predefinedStart).format(
              dateInitFormats.time
            ),
            endTime: moment(sessionDay.end || sessionDay.predefinedEnd).format(
              dateInitFormats.time
            ),
            iltSessionId: entityId
          })
        );
      })
    ).then((data) => {
      let items, session;
      data.forEach(({ payload: sessionDay }) => {
        const { updatedItems, updatedIltSession } = getItemsForUpdate(sessionDay);
        items = updatedItems;
        session = updatedIltSession;
      });
      dispatch(setData(items));
      dispatch(setDetails(session));
      populateWithData(session);
    });
  };

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'edit':
        return !entityId || !entityType || iltSessionDetails?.venues?.length === 0 || isLoading;
      default:
        return false;
    }
  };

  const populateWithData = (iltSession) => {
    if (!iltSessionDetails?.venues[0].id) {
      return;
    }

    dispatch(
      fetchMeetingRoomsByVenueId({
        filter: {
          page: 0,
          filters: {
            advancedFilters: scrubFiltersForBE({
              filters: [
                {
                  id: 'venueId',
                  operator: { key: 'contains' },
                  value: iltSessionDetails?.venues[0].id
                }
              ]
            })
          }
        }
      })
    )
      .then(({ payload }) => {
        return dispatch(
          fetchData({
            path: DIALOG_PATHS.MEETING_ROOMS,
            filter: {
              venueIds: iltSessionDetails.venues[0].id,
              sessionDayDate: moment(
                iltSession ? iltSession.startDateTime : iltSessionDetails.startDateTime
              ).format(moment.HTML5_FMT.DATE),
              meetingRoomIds: payload.content.slice(0, 3).map(({ id }) => id)
            },
            filterValues: iltSessionDetails?.venues
          })
        );
      })
      .then(() =>
        dispatch(fetchSessionDays({ entityId, filter: { meetingRoomExistence: false } }))
          .unwrap()
          .then(({ content }) => {
            dispatch(
              setDragAndDropDraggableItems({
                items: mapItemsToDraggable(content),
                saveInitial: true
              })
            );
            dispatch(setIsLoading(false));
          })
          .catch(() => setDragAndDropDraggableItems([]))
      );
  };

  const getItemsForUpdate = (sessionDay) => {
    let updatedIltSession = {};
    const updatedItems = iltSessions.map((iltSession) => {
      if (parseInt(iltSession.id, 10) !== parseInt(iltSessionDetails.id, 10)) {
        return iltSession;
      }

      if (!sessionDay) {
        return iltSession;
      }

      const { newStartTime, newEndTime } = getUpdatedSessionTime(
        sessionDay.dayDate,
        sessionDay.startTime,
        sessionDay.endTime,
        iltSessionDetails
      );

      // iltSessionDetails needs to be returned since iltSession doesn't have counts object returned from the BE
      if (newStartTime) {
        updatedIltSession['startDateTime'] = newStartTime;
      }

      if (newEndTime) {
        updatedIltSession['endDateTime'] = newEndTime;
      }

      return { ...iltSessionDetails, ...updatedIltSession };
    });

    return { updatedItems, updatedIltSession };
  };

  return (
    <div className={classes.root}>
      <TabContent>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h6" color="primary">
            {Localize.get('IltSession.SessionRooms')}
          </Typography>
          <Toolbar className={classes.toolbar}>
            {toolbarOptions?.map((item, index) => (
              <ToolbarItem
                key={index}
                item={item}
                isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                onToolbarItemClick={onToolbarItemClick}
              />
            ))}
          </Toolbar>
        </div>

        <TabContainer sx={{ justifyContent: 'center' }}>
          {isLoading ? (
            <CircularProgress disableShrink />
          ) : iltSessionDetails?.venues?.length ? (
            <DragandropCalendar
              entityId={entityId}
              fetchDataPath={DIALOG_PATHS.MEETING_ROOMS}
              customCss={{ calendarComponent: { height: '100%', width: '100%' } }}
            />
          ) : (
            <CustomNoDataOverlay />
          )}
        </TabContainer>

        <DragAndDropWithDialog
          entityId={entityId}
          dialogTitle="IltSession.SearchRoom"
          fetchDataPath={DIALOG_PATHS.MEETING_ROOMS}
          onIconClick={onDragAndDropDialogAction}
        />
      </TabContent>
    </div>
  );
};

export default SessionRoomsTable;
