import { isNil } from 'lodash';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  ACTION_MODES,
  dateInitFormats,
  NAVIGATE_TOASTER_TIMEOUT,
  SORT_DIRECTION
} from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import { deepMerge } from '@common/helpers/deepMerge';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { EVENTS_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { MASTER_LIST_PAGE_SIZE } from '@config/network';
import {
  deleteByPath,
  getByPathAndParams,
  patchByPathAndData,
  postByPathAndData
} from '@services/BaseApi';

import { SORT_DATA } from './util/sortConfig';

export const initialState = {
  selectedId: null,
  data: [],
  filter: {
    search: '',
    sortBy: SORT_DATA[0].name,
    sortDirection: SORT_DIRECTION.DESCENDING,
    page: 0,
    size: MASTER_LIST_PAGE_SIZE
  },
  totalPages: 0,
  totalElements: 0,
  details: null,
  isDetailsLoading: false,
  isLoading: true
};

export const EVENTS_SLICE = 'events';

export const fetchEvents = createAsyncThunk(
  `${EVENTS_SLICE}/fetchAll`,
  (filter, { rejectWithValue }) =>
    getByPathAndParams({
      path: EVENTS_PATHS.GET,
      params: filter
    })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response))
);

export const fetchEventDetails = createAsyncThunk(
  `${EVENTS_SLICE}/fetchDetails`,
  (selectedId, { rejectWithValue }) =>
    getByPathAndParams({
      path: EVENTS_PATHS.GET_DETAILS,
      pathVariables: { id: selectedId }
    })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error)),
  {
    condition: (selectedId) => Boolean(selectedId),
    dispatchConditionRejection: true
  }
);

export const createEvent = createAsyncThunk(
  `${EVENTS_SLICE}/create`,
  (postData, { rejectWithValue, dispatch }) => {
    const { startDateTime, endDateTime, registrationDueDate } = postData;
    const formatted = {
      startDateTime: dateToFormat(startDateTime, dateInitFormats.dateTime),
      endDateTime: dateToFormat(endDateTime, dateInitFormats.dateTime),
      registrationDueDate: dateToFormat(registrationDueDate, dateInitFormats.dateTime)
    };

    return postByPathAndData({
      path: EVENTS_PATHS.POST,
      data: {
        ...postData,
        startDateTime: formatted.startDateTime.split('.')[0],
        endDateTime: formatted.endDateTime.split('.')[0],
        registrationDueDate: formatted.registrationDueDate
          ? formatted.registrationDueDate.split('.')[0]
          : null,
        typeId: postData.typeId.id,
        minParticipants: postData?.minParticipants || 0,
        optParticipants: postData?.optParticipants || 0,
        countryId: postData?.country?.id || null
      }
    })
      .then((response) => {
        setTimeout(() => {
          dispatch(
            showSnackbar({
              message: successMessageFormatter(EntityTypes.EVENT, ACTION_MODES.Create),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
        }, NAVIGATE_TOASTER_TIMEOUT);
        return response.data;
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

export const deleteEvent = createAsyncThunk(
  `${EVENTS_SLICE}/delete`,
  (id, { dispatch, rejectWithValue }) =>
    deleteByPath({
      path: EVENTS_PATHS.DELETE,
      pathVariables: { id }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.EVENT, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.EVENT, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error.response);
      })
);

export const saveEvent = createAsyncThunk(
  `${EVENTS_SLICE}/save`,
  (params, { dispatch, rejectWithValue }) => {
    let postData = { ...params.postData };
    const { id } = params;

    if (postData.startDateTime) {
      postData.startDateTime = dateToFormat(postData.startDateTime, dateInitFormats.dateTime);
    }

    if (postData.endDateTime) {
      postData.endDateTime = dateToFormat(postData.endDateTime, dateInitFormats.dateTime);
    }

    if (postData.registrationDueDate) {
      postData.registrationDueDate = dateToFormat(
        postData.registrationDueDate,
        dateInitFormats.dateTime
      );
    }

    if (postData.status) {
      postData['statusId'] = postData.status.id;
      delete postData['status'];
    }

    if (postData.type) {
      postData['typeId'] = postData.type.id;
      delete postData['type'];
    }

    if (postData.nativeLanguage) {
      postData['nativeLanguageId'] = postData.nativeLanguage.id;
      delete postData['nativeLanguage'];
    }

    if (postData.venues?.length) {
      postData.venues = postData?.venues?.length ? postData.venues.map(({ id }) => ({ id })) : [];
      postData.countryId = null;
      postData.city = null;
      delete postData['country'];
    } else if (postData.country === '' || postData.country === null) {
      postData.countryId = null;
      delete postData['country'];
    }

    if (postData?.country?.id) {
      postData.countryId = postData?.country?.id;
      delete postData['country'];
    }

    if (postData.optParticipants === '') {
      postData.optParticipants = 0;
    }

    if (postData.minParticipants === '') {
      postData.minParticipants = 0;
    }

    return patchByPathAndData({
      path: EVENTS_PATHS.PATCH,
      data: postData,
      pathVariables: { id }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.EVENT, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return response.data;
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

export const eventsSlice = createSlice({
  name: EVENTS_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setDetails: (state, { payload }) => {
      state.details = deepMerge(state.details, payload);
    },
    deleteSuccess: (state, action) => {
      state.data = [...state.data.filter((item) => item.id !== action.payload.id)];
      state.selectedId = [...state.data.filter((item) => item.id !== action.payload.id)];
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
      state.selectedId = null;
    },
    setIsDetailsLoading: (state, { payload }) => {
      state.isDetailsLoading = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchEvents.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload?.content || state.data;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.selectedId = state.selectedId ? state.selectedId : payload?.content?.[0]?.id;
        state.filter.page = payload?.pageable?.pageNumber;
        state.filter.size = payload?.pageable?.pageSize;
      })
      .addCase(fetchEvents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEvents.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      // Get Details
      .addCase(fetchEventDetails.fulfilled, (state, { payload }) => {
        // TODO: Mocked
        state.details = payload
          ? {
              ...payload,
              currentParticipants: 0,
              orderNumberInGroup: payload.orderNumberInGroup ?? '',
              registrationDueDate: payload.registrationDueDate ?? '',
              city: payload.city ?? '',
              participantsCount: [
                payload.totalToBeInvited,
                payload.totalInvited,
                payload.totalConfirmed,
                payload.totalCanceled,
                payload.totalCompleted,
                payload.totalRegistered,
                payload.totalReserved
              ]
            }
          : null;
        state.isDetailsLoading = false;
      })
      .addCase(fetchEventDetails.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(fetchEventDetails.rejected, (state) => {
        state.details = null;
        state.isDetailsLoading = false;
      })
      // Create
      .addCase(createEvent.fulfilled, (state) => {
        state.filter.page = 0;
      })
      // Update pending
      .addCase(saveEvent.pending, (state, action) => {
        const { postData } = action.meta.arg;

        state.isDetailsLoading = true;

        // Must convert moment object
        if (postData.startDateTime) {
          postData.startDateTime = dateToFormat(postData.startDateTime, dateInitFormats.dateTime);
        }

        if (postData.endDateTime) {
          postData.endDateTime = dateToFormat(postData.endDateTime, dateInitFormats.dateTime);
        }

        if (postData.registrationDueDate) {
          postData.registrationDueDate = dateToFormat(
            postData.registrationDueDate,
            dateInitFormats.dateTime
          );
        }
      })
      //Update failed
      .addCase(saveEvent.rejected, (state) => {
        state.isDetailsLoading = false;
      })
      // Update success
      .addCase(saveEvent.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.data = state.data.map((item) =>
          item.id === action.meta.arg.id ? deepMerge(item, action.meta.arg.postData) : item
        );
        state.details = deepMerge(state.details, action.meta.arg.postData);
      })
      // Delete success
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.totalElements -= 1;
        state.isLoading = false;
        state.details = null;
        state.data = state.data.filter((item) => item.id !== action.meta.arg);
        state.selectedId = state.data.filter((item) => item.id !== action.meta.arg)[0]?.id;

        if (state.data.length === 0 && state.totalPages === 1) {
          state.filter.page = 0;
          state.totalPages = 0;
          state.totalElements = 0;
        } else if (state.data.length === 0 && state.totalPages > 1) {
          state.filter.page -= 1;
        }
      });
  }
});

export const selectId = (state) => state.EVENTS_SLICE.selectedId;
export const selectList = (state) => state.EVENTS_SLICE.data;
export const selectFilter = (state) => state.EVENTS_SLICE.filter;
export const selectTotalElements = (state) => state.EVENTS_SLICE.totalElements;
export const selectTotalPages = (state) => state.EVENTS_SLICE.totalPages;
export const selectDetails = (state) => state.EVENTS_SLICE.details;
export const selectIsLoading = (state) => state.EVENTS_SLICE.isLoading;
export const selectIsDetailsLoading = (state) => state.EVENTS_SLICE.isDetailsLoading;

const { actions, reducer } = eventsSlice;

export const {
  setError,
  setLoading,
  setSelectedId,
  setConfirmationDialog,
  deleteSuccess,
  setFilterParams,
  resetPage,
  resetState,
  setDetails,
  setIsDetailsLoading
} = actions;

export default reducer;
