import { isNil } from 'lodash';
import moment from 'moment/moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import { deepMerge } from '@common/helpers/deepMerge';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { EVENTS_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { SnackbarSeverityTypes, showSnackbar } from '@components/Snackbar/snackbarSlice';
import { TABLE_OPTIONS } from '@config/network';
import { setDetails as setEventDetails } from '@pages/Events/eventsSlice';
import {
  deleteByPath,
  getByPathAndParams,
  patchByPathAndData,
  postByPathAndData
} from '@services/BaseApi';

export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0]
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: [],
  isDetailsLoading: false,
  details: {}
};

export const EVENTS_HOTELS_SLICE = 'eventsHotels';

export const fetchHotels = createAsyncThunk(
  `${EVENTS_HOTELS_SLICE}/hotels`,
  ({ entityId, filter }, { rejectWithValue }) => {
    return getByPathAndParams({
      path: EVENTS_PATHS.GET_CONTINGENTS,
      pathVariables: { id: entityId },
      params: filter
    })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response));
  }
);

export const saveHotels = createAsyncThunk(
  `${EVENTS_HOTELS_SLICE}/save`,
  (postData, { dispatch, rejectWithValue }) => {
    if (postData.postData.accommodation) {
      postData.postData.accommodationId = postData.postData.accommodation.id;
      delete postData.postData.accommodation;
    }
    if (postData.postData.currency) {
      postData.postData.currencyId = postData.postData.currency.id;
      delete postData.postData.currency;
    }
    return patchByPathAndData({
      path: EVENTS_PATHS.PATCH_CONTINGENTS,
      data: { ...postData.postData },
      pathVariables: { eventId: postData.eventId, contingentId: postData.contingentId }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.HOTEL, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.HOTEL, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
        return rejectWithValue(error?.response);
      });
  }
);

export const createEventsHotelContingent = createAsyncThunk(
  `${EVENTS_HOTELS_SLICE}/create`,
  ({ data, entityId }, { rejectWithValue, dispatch, getState }) => {
    return postByPathAndData({
      path: EVENTS_PATHS.POST_CONTINGENT.replace(':id', entityId),
      data: {
        startDate: moment(data.startDate).format(moment.HTML5_FMT.DATE),
        endDate: moment(data.startDate).format(moment.HTML5_FMT.DATE),
        validUntil: data.validUntil ? moment(data.validUntil).format(moment.HTML5_FMT.DATE) : null,
        price: data?.price,
        booked: data?.booked,
        accommodationId: data?.accommodation?.id,
        currencyId: data?.currency,
        hotelId: data?.hotel?.id,
        contingent: data.contingent
      }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.CONTINGENT, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        dispatch(
          fetchHotels({
            entityId: entityId,
            filter: getState().EVENTS_HOTELS_SLICE.filter
          })
        );
        dispatch(setEventDetails({ counts: { [TAB_KEYS.HOTEL]: response.data.count } }));
        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.HOTEL, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
        return rejectWithValue(error?.response);
      });
  }
);

export const deleteHotels = createAsyncThunk(
  `${EVENTS_HOTELS_SLICE}/delete`,
  ({ id, contingentIds }, { dispatch, getState, rejectWithValue }) => {
    return deleteByPath({
      path: EVENTS_PATHS.DELETE_CONTINGENT,
      pathVariables: { id, contingentIds }
    })
      .then((response) => {
        dispatch(fetchHotels({ entityId: id, filter: getState().EVENTS_HOTELS_SLICE.filter }));
        dispatch(setEventDetails({ counts: { [TAB_KEYS.HOTEL]: response.data.count } }));
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.CONTINGENT, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return { response, contingentIds };
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.HOTEL, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error.response);
      });
  }
);

export const fetchHotelDetails = createAsyncThunk(
  `${EVENTS_HOTELS_SLICE}/fetchDetails`,
  (selectedId) =>
    getByPathAndParams({
      path: EVENTS_PATHS.GET_CONTINGENTS_HOTEL,
      pathVariables: { id: selectedId }
    })
      .then((response) => response.data)
      .catch((error) => error),
  {
    condition: (selectedId) => Boolean(selectedId),
    dispatchConditionRejection: true
  }
);

export const eventsHotelTableSlice = createSlice({
  name: EVENTS_HOTELS_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setHotelDetails: (state, { payload }) => {
      state.details = deepMerge(state.details, payload);
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};
      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }
      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }
      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchHotels.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.data = payload?.content ?? [];
      })
      .addCase(fetchHotels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHotels.rejected, (state) => {
        state.isLoading = false;
        state.data = [];
      })
      .addCase(saveHotels.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.data = state.data.map((item) =>
          item.id === action.meta.arg.id ? deepMerge(item, action.payload) : item
        );
        state.details = deepMerge(state.details, action.payload);
      })
      .addCase(saveHotels.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(saveHotels.rejected, (state) => {
        state.isDetailsLoading = false;
      })
      .addCase(deleteHotels.fulfilled, (state, { payload }) => {
        const { contingentIds } = payload;
        state.selectionModel = [];
        state.data = state.data.filter(({ id }) => !contingentIds.includes(id));
        state.totalElements -= contingentIds.length;
      })
      .addCase(fetchHotelDetails.fulfilled, (state) => {
        state.details = null;
        state.isDetailsLoading = false;
      })
      .addCase(fetchHotelDetails.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(fetchHotelDetails.rejected, (state) => {
        state.details = null;
        state.isDetailsLoading = false;
      });
  }
});

export const selectList = (state) => state.EVENTS_HOTELS_SLICE.data;
export const selectRows = (state) => state.EVENTS_HOTELS_SLICE.rows;
export const selectTotalElements = (state) => state.EVENTS_HOTELS_SLICE.totalElements;
export const selectTotalPages = (state) => state.EVENTS_HOTELS_SLICE.totalPages;
export const selectHotelDetails = (state) => state.EVENTS_HOTELS_SLICE.details;
export const selectFilter = (state) => state.EVENTS_HOTELS_SLICE.filter;
export const selectIsLoading = (state) => state.EVENTS_HOTELS_SLICE.isLoading;
export const selectSelectionModel = (state) => state.EVENTS_HOTELS_SLICE.selectionModel;
export const selectIsDetailsLoading = (state) => state.EVENTS_HOTELS_SLICE.isDetailsLoading;

const { actions, reducer } = eventsHotelTableSlice;

export const { setData, setFilterParams, resetState, setSelectionModel, setHotelDetails } = actions;

export default reducer;
