import { Navigate } from 'react-location';

import { BUSINESS_PARTNERS_PATHS, LOCALES_PATHS, SETTINGS_PATH } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import Contacts, { CreateContact } from '@pages/Contacts';
import { getByPathAndParams } from '@services/BaseApi';

import { contactsPath, newPath, rootPath } from '../Routes';

export const resolveBusinessPartnerOrLocation = (params) => {
  if (!params?.search?.locationId && !params?.search?.businessPartnerId) {
    return Promise.resolve({});
  }

  const idToFetch = params?.search?.locationId || params?.search?.businessPartnerId;
  return getByPathAndParams({
    path: BUSINESS_PARTNERS_PATHS.GET_DETAILS,
    pathVariables: { id: idToFetch }
  });
};

const ContactRoute = (routeCache) => ({
  path: contactsPath,
  children: [
    {
      path: rootPath,
      element: <Contacts />,
      loader: routeCache.createLoader(
        async () => ({
          config: await getByPathAndParams({
            path: SETTINGS_PATH.GET,
            pathVariables: { tile: EntityTypes.CONTACT }
          }),
          countries: await getByPathAndParams({
            path: LOCALES_PATHS.COUNTRIES
          })
        }),
        { policy: 'cache-first' }
      )
    },
    {
      path: newPath,
      element: <CreateContact />,
      loader: routeCache.createLoader(async (params) => {
        return {
          organizationPerson: await resolveBusinessPartnerOrLocation(params),
          isNavigatedFromContactsTab: params?.search?.locationId
            ? 'location'
            : params?.search?.businessPartnerId
            ? 'businessPartner'
            : ''
        };
      })
    },
    {
      element: <Navigate to={contactsPath} />
    }
  ]
});

export default ContactRoute;
