const webinarSidebar = [
  {
    groupName: 'IltSession.IltSessionInformation',
    fields: [
      {
        label: 'Labels.Status',
        name: 'completed',
        required: true,
        disabled: false,
        component: 'select'
      }
    ]
  },
  {
    groupName: 'ParticipantsTile.Information',
    fields: [
      {
        label: 'Labels.FirstName',
        name: 'personDto.firstName',
        required: false,
        disabled: true,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'Labels.LastName',
        name: 'personDto.lastName',
        required: false,
        disabled: true,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'Labels.Company',
        name: 'company',
        required: false,
        disabled: true,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'Labels.Status',
        name: 'status.value',
        required: true,
        disabled: false,
        component: 'select'
      },
      {
        label: 'PriceTypes.StandardPrice',
        name: 'standardPrice',
        required: true,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'PriceTypes.AdvertisingSubsidy',
        name: 'advertisingSubsidyPrice',
        required: true,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'ResourcesTile.Currency',
        name: 'currency.id',
        required: true,
        disabled: false,
        component: 'select'
      }
    ]
  },
  {
    groupName: 'IltSession.SessionDaysStatus',
    arrayName: 'sessions',
    fields: [
      {
        label: 'Labels.Name',
        name: 'session.name',
        required: false,
        disabled: true,
        component: 'text'
      },
      {
        label: 'Labels.Attended',
        name: 'attended',
        required: false,
        disabled: true,
        component: 'switch'
      }
    ]
  }
];

const classroomSidebar = [
  {
    groupName: 'IltSession.IltSessionInformation',
    fields: [
      {
        label: 'Labels.Status',
        name: 'completed',
        required: true,
        disabled: false,
        component: 'select'
      }
    ]
  },
  {
    groupName: 'ParticipantsTile.Information',
    fields: [
      {
        label: 'Labels.FirstName',
        name: 'personDto.firstName',
        required: false,
        disabled: true,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'Labels.LastName',
        name: 'personDto.lastName',
        required: false,
        disabled: true,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'Labels.Company',
        name: 'company',
        required: false,
        disabled: true,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'Labels.Status',
        name: 'status.value',
        required: true,
        disabled: false,
        component: 'select'
      },
      {
        label: 'PriceTypes.StandardPrice',
        name: 'standardPrice',
        required: true,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'PriceTypes.AdvertisingSubsidy',
        name: 'advertisingSubsidyPrice',
        required: true,
        disabled: false,
        component: 'text',
        type: 'number'
      },
      {
        label: 'ResourcesTile.Currency',
        name: 'currency.id',
        required: true,
        disabled: false,
        component: 'select'
      }
    ]
  },
  {
    groupName: 'Hotel.Information',
    name: 'hotels',
    fields: [
      {
        label: 'IltSession.HotelRequest',
        name: 'hotelRequest',
        required: false,
        disabled: false,
        component: 'switch'
      },
      {
        label: 'Hotel.Name',
        name: 'hotels[0].name',
        required: false,
        disabled: true,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'Hotel.ArrivalDate',
        name: 'arrivalDate',
        required: false,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'Hotel.DepartureDate',
        name: 'departureDate',
        required: false,
        disabled: false,
        component: 'datepicker'
      }
    ]
  },
  {
    groupName: 'IltSession.SessionDaysStatus',
    arrayName: 'sessions',
    fields: [
      {
        label: 'Labels.Name',
        name: 'session.name',
        required: false,
        disabled: true,
        component: 'text'
      },
      {
        label: 'Labels.Attended',
        name: 'attended',
        required: false,
        disabled: true,
        component: 'switch'
      }
    ]
  }
];

export { webinarSidebar, classroomSidebar };
