import Localize from 'react-intl-universal';

import { isNil } from 'lodash';
import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import { deepMerge } from '@common/helpers/deepMerge';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { EXPORTS_PATHS, ILT_SESSION_PATHS } from '@common/network/ApiPaths';
import ClassNamesForExport from '@common/network/ClassNamesForExport';
import EntityTypes from '@common/network/EntityTypes';
import { SnackbarSeverityTypes, showSnackbar } from '@components/Snackbar/snackbarSlice';
import { TABLE_OPTIONS } from '@config/network';
import { setDetails } from '@pages/IltSession/iltSessionSlice';
import {
  deleteByPath,
  getByPathAndParams,
  postByPathAndData,
  putByPathAndData
} from '@services/BaseApi';

export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0]
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: [],
  isDetailsLoading: false,
  details: {},
  exportAnchor: null,
  isExportPopupOpen: false
};

export const ILT_SESSION_HOTELS_SLICE = 'iltSessionHotels';

export const fetchHotels = createAsyncThunk('iltSessionHotels/hotels', (filterObj) => {
  return getByPathAndParams({
    path: ILT_SESSION_PATHS.GET_CONTINGENTS,
    pathVariables: { id: filterObj.entityId },
    params: filterObj.filter
  })
    .then(({ data }) => data)
    .catch((error) => error);
});

export const fetchHotelDetails = createAsyncThunk(
  `${ILT_SESSION_HOTELS_SLICE}/fetchDetails`,
  (selectedId) =>
    getByPathAndParams({
      path: ILT_SESSION_PATHS.GET_CONTINGENTS_HOTEL,
      pathVariables: { id: selectedId }
    })
      .then((response) => response.data)
      .catch((error) => error),
  {
    condition: (selectedId) => Boolean(selectedId),
    dispatchConditionRejection: true
  }
);

export const deleteHotels = createAsyncThunk(
  `${ILT_SESSION_HOTELS_SLICE}/delete`,
  ({ id, contingentIds }, { dispatch, rejectWithValue }) => {
    return deleteByPath({
      path: ILT_SESSION_PATHS.DELETE_CONTINGENT,
      pathVariables: { id, contingentIds }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.CONTINGENT, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return { response, contingentIds };
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

export const createIltHotelContingent = createAsyncThunk(
  `${ILT_SESSION_HOTELS_SLICE}/create`,
  ({ data, sessionId }, { rejectWithValue, dispatch, getState }) => {
    return postByPathAndData({
      path: ILT_SESSION_PATHS.POST_CONTINGENT.replace(':id', sessionId),
      data: {
        startDate: moment(data.startDate).format(moment.HTML5_FMT.DATE),
        endDate: moment(data.endDate).format(moment.HTML5_FMT.DATE),
        validUntil: data.validUntil ? moment(data.validUntil).format(moment.HTML5_FMT.DATE) : null,
        price: data?.price,
        booked: data?.booked,
        accommodationId: data?.accommodation?.id,
        currencyId: data?.currency,
        hotelId: data?.hotel?.id,
        contingent: data.contingent
      }
    })
      .then(async (response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.CONTINGENT, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        const { totalElements = 0 } = await dispatch(
          fetchHotels({
            entityId: getState().ILT_SESSION_SLICE.selectedId,
            filter: getState().ILT_SESSION_HOTELS_SLICE.filter
          })
        ).unwrap();
        dispatch(setDetails({ counts: { [TAB_KEYS.HOTEL]: totalElements } }));

        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.CONTINGENT, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
        return rejectWithValue(error?.response);
      });
  }
);

export const saveHotels = createAsyncThunk(
  `${ILT_SESSION_HOTELS_SLICE}save`,
  (postData, { dispatch, rejectWithValue }) =>
    putByPathAndData({
      path: ILT_SESSION_PATHS.PUT_CONTINGENTS,
      data: {
        startDate: postData?.startDate,
        endDate: postData?.endDate,
        validUntil: postData?.validUntil,
        price: postData?.price,
        booked: postData?.booked,
        accommodationId: postData?.accommodation?.id,
        currencyId: postData?.currency?.id,
        hotelId: postData?.hotel?.id,
        contingent: postData.contingent
      },
      pathVariables: { iltSessionId: postData.iltSessionId, id: postData.id }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.HOTEL, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return response.data;
      })
      .catch((error) => rejectWithValue(error.response))
);

export const generateReport = createAsyncThunk(
  `${ILT_SESSION_HOTELS_SLICE}/export`,
  ({ id, type }, { rejectWithValue, dispatch }) => {
    return getByPathAndParams({
      path: EXPORTS_PATHS.GET.replace(':className', ClassNamesForExport.HOTELS)
    }).then(({ data: fieldsToExport = [] }) => {
      return getByPathAndParams({
        path: ILT_SESSION_PATHS.EXPORT_CONTINGENTS,
        pathVariables: { iltSessionId: id, fileType: type, fieldsToExport },
        responseType: 'blob'
      })
        .then((response) => {
          dispatch(setExportPopupClosed());
          dispatch(
            showSnackbar({
              message: Localize.get('SuccessMessage.Download', {
                entity: Localize.get('Hotel.List')
              }),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
          return response;
        })
        .catch((error) => {
          dispatch(
            showSnackbar({
              message: Localize.get('InitializeError.Download', {
                entity: Localize.get('Hotel.List')
              }),
              severity: SnackbarSeverityTypes.ERROR
            })
          );
          return rejectWithValue(error);
        });
    });
  }
);

export const iltSessionHotelTableSlice = createSlice({
  name: ILT_SESSION_HOTELS_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setExportPopupOpened: (state, { payload }) => {
      state.exportAnchor = payload;
      state.isExportPopupOpen = true;
    },
    setExportPopupClosed: (state) => {
      state.exportAnchor = null;
      state.isExportPopupOpen = false;
    },
    setHotelDetails: (state, { payload }) => {
      state.details = deepMerge(state.details, payload);
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};
      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }
      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }
      state.filter = { ...state.filter, ...newFilterValues, page: action.payload.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchHotels.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.data = payload.content;
      })
      .addCase(fetchHotels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHotels.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      .addCase(deleteHotels.fulfilled, (state, { payload }) => {
        const { contingentIds } = payload;
        state.selectionModel = [];
        state.data = state.data.filter(({ id }) => !contingentIds.includes(id));
        state.totalElements -= contingentIds.length;
      })
      // Get Details
      .addCase(fetchHotelDetails.fulfilled, (state) => {
        state.details = null;
        state.isDetailsLoading = false;
      })
      .addCase(fetchHotelDetails.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(fetchHotelDetails.rejected, (state) => {
        state.details = null;
        state.isDetailsLoading = false;
      })
      // Update hotels
      .addCase(saveHotels.fulfilled, (state, action) => {
        state.isDetailsLoading = false;
        state.data = state.data.map((item) =>
          item.id === action.meta.arg.id ? deepMerge(item, action.meta.arg) : item
        );
        state.details = deepMerge(state.details, action.meta.arg);
      })
      .addCase(saveHotels.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(saveHotels.rejected, (state) => {
        state.isDetailsLoading = false;
      })
      .addCase(generateReport.fulfilled, (state, { payload }) => {
        let [, filename] = payload.headers['content-disposition'].split('filename=');
        filename = filename.replaceAll('"', '');

        const link = document.createElement('a');
        document.body.appendChild(link);
        link.style = 'display: none';
        link.setAttribute('download', filename);
        const blob = new Blob([payload.data]);
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
});

export const selectList = (state) => state.ILT_SESSION_HOTELS_SLICE.data;
export const selectRows = (state) => state.ILT_SESSION_HOTELS_SLICE.rows;
export const selectTotalElements = (state) => state.ILT_SESSION_HOTELS_SLICE.totalElements;
export const selectTotalPages = (state) => state.ILT_SESSION_HOTELS_SLICE.totalPages;
export const selectHotelDetails = (state) => state.ILT_SESSION_HOTELS_SLICE.details;
export const selectFilter = (state) => state.ILT_SESSION_HOTELS_SLICE.filter;
export const selectIsLoading = (state) => state.ILT_SESSION_HOTELS_SLICE.isLoading;
export const selectSelectionModel = (state) => state.ILT_SESSION_HOTELS_SLICE.selectionModel;
export const selectIsDetailsLoading = (state) => state.ILT_SESSION_HOTELS_SLICE.isDetailsLoading;
export const selectExportAnchor = (state) => state.ILT_SESSION_HOTELS_SLICE.exportAnchor;
export const selectIsExportPopupOpen = (state) => state.ILT_SESSION_HOTELS_SLICE.isExportPopupOpen;

const { actions, reducer } = iltSessionHotelTableSlice;

export const {
  setData,
  setFilterParams,
  resetState,
  setSelectionModel,
  setHotelDetails,
  setExportPopupOpened,
  setExportPopupClosed
} = actions;

export default reducer;
