import React from 'react';

import NoData from '@components/NoData';
import Tabs from '@components/Tabs';
import tabsConfig, {
  LOCATIONS_TABS_COMPONENTS,
  hotelTabs,
  hotelAndVenueTabs,
  venueTabs
} from '@pages/Locations/components/Tabs/tabsConfig';

export const SUPPLIER_HOTEL_AND_VENUE_TYPES = 1;
export const HOTEL_AND_VENUE_TYPES = 4;
export const VENUE_TYPES = 2;

const LocationsTabs = ({
  entityId = '',
  entityType = '',
  details = null,
  setDetails = () => {}
}) => {
  const { roles = [] } = details || {};
  const isHotel = roles[0]?.supplierType?.id === SUPPLIER_HOTEL_AND_VENUE_TYPES;
  const isHotelAndVenue = roles[0]?.supplierType?.id === HOTEL_AND_VENUE_TYPES;
  const isVenue = roles[0]?.supplierType?.id === VENUE_TYPES;

  return (
    <Tabs
      tabsConfig={
        isHotel ? hotelTabs : isHotelAndVenue ? hotelAndVenueTabs : isVenue ? venueTabs : tabsConfig
      }
      count={details?.counts ?? 0}
      renderContent={(tab) => {
        const DynamicComponent = LOCATIONS_TABS_COMPONENTS[tab?.key];
        return DynamicComponent ? (
          <DynamicComponent
            entityId={entityId}
            entityType={entityType}
            columns={tab?.columns}
            toolbarOptions={tab?.options}
            allowedEntityId={8}
            details={details}
            setDetails={setDetails}
            key={entityId}
          />
        ) : (
          <NoData />
        );
      }}
    />
  );
};

export default LocationsTabs;
