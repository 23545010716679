// Available filter values supported on BE:
// Attachments Tab - Filter by fields: name, createdAt
// Notes Tab - Filter by fields: text, type
// Accommodations Tab (Hotel tile) - Filter by fields: roomType, roomSize, occupancy, total
// Contacts Tab (Hotel tile) - Filter by fields: locationName, name, function, department
// Participants Tab (Hotel tile) - Filter by fields: name, startDate, endDate, type
// Contingents Tab (Hotel tile) - Filter by fields: year, roomType, contingent, booked, price
import {
  getGridStringOperators,
  getGridNumericOperators,
  getGridDateOperators,
  getGridBooleanOperators
} from '@mui/x-data-grid';

import { FILTER_KEYS } from '@components/FilterDialog';

const availableFilterValues = ['contains', 'startsWith', 'endsWith', 'equals'];
const eventDialogOperators = ['contains'];
const equalNumberDialogOperators = ['='];

export const BACKEND_FILTER_VALUES = {
  contains: 'contains',
  startsWith: 'starting',
  endsWith: 'ending',
  equals: 'equal'
};

export const MUI_TABLE_FILTER_OPERATORS = {
  contains: FILTER_KEYS.Contains,
  startsWith: FILTER_KEYS.StartsWith,
  endsWith: FILTER_KEYS.EndsWith,
  '=': FILTER_KEYS.Equals,
  '!=': FILTER_KEYS.NotEqual,
  '>': FILTER_KEYS.GreaterThen,
  '<': FILTER_KEYS.LessThen,
  '>=': FILTER_KEYS.GreaterThenOrEqual,
  '<=': FILTER_KEYS.LessThenOrEqual,
  isEmpty: FILTER_KEYS.IsEmpty,
  isNotEmpty: FILTER_KEYS.IsNotEmpty,
  equals: FILTER_KEYS.Equals,
  not: FILTER_KEYS.IsNotOn,
  is: FILTER_KEYS.IsOn,
  after: FILTER_KEYS.IsAfter,
  onOrAfter: FILTER_KEYS.IsOnOrAfter,
  before: FILTER_KEYS.IsBefore,
  onOrBefore: FILTER_KEYS.IsOnOrBefore
};

export const attachmentTableFilters = getGridStringOperators()
  .filter((operator) => availableFilterValues.includes(operator.value))
  .map((operator) => operator);

export const containsOnlyDialogFilters = getGridStringOperators().filter(({ value }) =>
  eventDialogOperators.includes(value)
);

export const equalNumbersDialogFilters = getGridNumericOperators().filter(({ value }) =>
  equalNumberDialogOperators.includes(value)
);

export const getFilterOperatorsBasedOnType = (type) => {
  switch (type) {
    case 'number':
      return getGridNumericOperators().filter(({ value }) => !['isAnyOf'].includes(value));

    case 'date':
      return getGridDateOperators();

    case 'boolean':
      return getGridBooleanOperators();

    default:
      return getGridStringOperators().filter(({ value }) => !['isAnyOf'].includes(value));
  }
};
