import { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useSearch } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';

import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import { replaceAll } from '@common/helpers/string';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import { saveState } from '@common/storage/persistSlice';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';
import { BACKEND_FILTER_VALUES } from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';
import { eventsSessionDetailsPath } from '@navigation/routes/Routes';
import { CreateSession } from '@pages/Events/components/Tabs/SessionsTable';
import {
  selectDetails as selectEventsDetails,
  selectFilter as selectEventsFilter
} from '@pages/Events/eventsSlice';

import {
  selectIsLoading,
  selectData,
  selectFilter,
  selectTotalElements,
  selectSelectionModel,
  fetchSessions,
  setFilterUpdate,
  setSelectionModel
} from './sessionsSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  container: {
    height: 400,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  }
}));

const SessionsTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarOptions = []
}) => {
  const search = useSearch();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const eventFilter = useSelector(selectEventsFilter);
  const details = useSelector(selectEventsDetails);

  const isLoading = useSelector(selectIsLoading);
  const data = useSelector(selectData);
  const filter = useSelector(selectFilter);
  const totalElements = useSelector(selectTotalElements);
  const selectionModel = useSelector(selectSelectionModel);

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(fetchSessions({ entityId, filter }));
    }
  }, [entityId, filter?.match, filter?.page, filter?.search, filter?.searchValue, filter?.size]);

  const onRowClick = ({ id }) => {
    const replaceMap = { ':eventId': entityId, ':id': id };
    const path = replaceAll(eventsSessionDetailsPath, replaceMap);

    dispatch(saveState({ filter: eventFilter, selectedId: entityId }));
    navigate({ to: path, search: () => ({ activeTab: TAB_KEYS.OVERVIEW }), replace: false });
  };

  const onToolbarItemClick = (fieldName, files) => {
    switch (fieldName) {
      case 'add_sessions':
        if (files.length > 1) {
          alert('Batch upload currently not supported');
          return;
        }

        dispatch(saveState({ filter: eventFilter, selectedId: entityId }));
        navigate({
          search: (previousUrlParams) => ({ ...previousUrlParams, mode: ACTION_MODES.Create })
        });

        return;
      case 'delete': {
        if (selectionModel.length > 1) {
          alert('Batch delete currently not supported');
          return;
        }
        return;
      }

      default:
        return false;
    }
  };

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add_sessions':
        return !entityId || !entityType;
      case 'delete':
        return true; //selectionModel.length === 0;
      default:
        return false;
    }
  };

  return (
    <div className={classes.root}>
      {search?.mode === ACTION_MODES.Create ? (
        <CreateSession event={details} />
      ) : (
        <TabContent key={entityId}>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('Labels.Sessions')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              {toolbarOptions?.map((item, index) => (
                <ToolbarItem
                  key={index}
                  item={item}
                  isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                  onToolbarItemClick={onToolbarItemClick}
                />
              ))}
            </Toolbar>
          </div>
          <TabContainer>
            <DataGrid
              loading={isLoading}
              rows={data}
              getRowClassName={() => classes.tableRow}
              columns={[
                ...columns.map((column) => ({
                  ...column,
                  headerName: Localize.get(column.headerName),
                  renderCell: (params) => (
                    <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                      {['name'].includes(params.field) ? (
                        <Tooltip title={params.value} arrow>
                          <Link
                            sx={{
                              display: 'block',
                              cursor: 'pointer',
                              maxWidth: params?.colDef?.width,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                            onClick={() => onRowClick(params)}
                          >
                            {params?.value}
                          </Link>
                        </Tooltip>
                      ) : (
                        <Typography variant="body" component="div">
                          {params.value}
                        </Typography>
                      )}
                    </div>
                  )
                }))
              ]}
              localeText={getLocalesText(Localize)}
              paginationMode="server"
              sortingMode="server"
              checkboxSelection
              disableColumnMenu
              page={filter?.page}
              pageSize={filter?.size}
              rowCount={totalElements}
              rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
              disableSelectionOnClick
              components={{ Toolbar: TableToolbar }}
              onSelectionModelChange={(values) => {
                dispatch(setSelectionModel(isLoading ? [] : values));
              }}
              selectionModel={selectionModel}
              onPageChange={(value) =>
                dispatch(setFilterUpdate({ value: { ...filter, page: value } }))
              }
              onPageSizeChange={(value) =>
                dispatch(setFilterUpdate({ value: { ...filter, size: value } }))
              }
              onFilterModelChange={(values) => {
                // For now only one filter can be passed to BE
                const activeFilter = values.items[0];
                if (!activeFilter) {
                  return;
                }

                dispatch(
                  setFilterUpdate({
                    value: {
                      search: activeFilter.columnField,
                      searchValue: activeFilter.value,
                      match: BACKEND_FILTER_VALUES[activeFilter.operatorValue] // map with backend values
                    }
                  })
                );
              }}
            />
          </TabContainer>
        </TabContent>
      )}
    </div>
  );
};

export default SessionsTable;
