import { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import getLocalesText from '@common/helpers/tables/getLocalesText';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';

import SessionsDialog from './SessionsDialog';
import {
  selectTotalPages,
  selectIsLoading,
  selectData,
  selectSelectionModel,
  selectIsSearchDialogOpen,
  setSelectionModel,
  openSessionsDialog,
  closeSessionsDialog,
  getSessions,
  updateSessions,
  removeSessions
} from './sessionsTableSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  }
}));

const SessionsTable = ({
  columns = [],
  eventId = null,
  entityId = null,
  entityType = null,
  toolbarOptions = [],
  setDetails = () => {}
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);
  const data = useSelector(selectData);
  const selectionModel = useSelector(selectSelectionModel);
  const isSearchDialogOpen = useSelector(selectIsSearchDialogOpen);
  const totalPages = useSelector(selectTotalPages);

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(getSessions({ entityId, entityType }));
    }
  }, [entityId, entityType]);

  const onToolbarItemClick = (fieldName) => {
    switch (fieldName) {
      case 'add_sessions':
        dispatch(openSessionsDialog());
        return;
      case 'delete': {
        dispatch(
          openConfirmDialog({
            title: Localize.get('ConfirmationMessages.Delete', {
              item: Localize.get('ParticipantsTile.SingularOrPluralSession', {
                selectionModel: selectionModel.length
              })?.toLowerCase()
            }),
            confirmButton: Localize.get('Buttons.Delete'),
            cancelButton: Localize.get('Buttons.Cancel'),
            disabler: { confirm: isLoading, cancel: isLoading }
          })
        )
          .unwrap()
          .then((result) => {
            if (result === CONFIRM_ACTIONS.Confirm) {
              removeConfirmed();
            }
          });

        return;
      }
      default:
        return false;
    }
  };

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add_sessions':
        return !entityId || !entityType;
      case 'delete':
        return selectionModel.length === 0;
      default:
        return false;
    }
  };

  const saveSessions = (value) => {
    if (!value) {
      dispatch(closeSessionsDialog());
      return;
    }
    dispatch(updateSessions({ postData: value, entityId, setDetails }));
  };

  const removeConfirmed = () => {
    if (!entityId && !selectionModel.length) {
      dispatch(closeSessionsDialog());
      return;
    }
    dispatch(removeSessions({ participantId: entityId, sessionIds: selectionModel, setDetails }));
  };

  return (
    <div className={classes.root}>
      <TabContent>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h2" color="primary">
            {Localize.get('Labels.Sessions')}
          </Typography>
          <Toolbar className={classes.toolbar}>
            {toolbarOptions?.map((item, index) => (
              <ToolbarItem
                data-test-id={`ToolbarItem-session-${index}`}
                key={index}
                item={item}
                isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                onToolbarItemClick={onToolbarItemClick}
              />
            ))}
          </Toolbar>
        </div>
        <TabContainer>
          <StyledDataGrid
            rowCount={totalPages}
            loading={isLoading}
            rows={data}
            disableColumnMenu
            filterMode="server"
            selectionModel={selectionModel}
            columns={[
              ...columns.map((column) => ({
                ...column,

                renderCell: (params) => (
                  <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                    {['session.name'].includes(params.field) ? (
                      <Tooltip title={params.value} arrow>
                        <Typography
                          noWrap
                          variant="body"
                          component="div"
                          sx={{
                            maxWidth: params?.colDef?.width - 10 // 10 px for three dots
                          }}
                        >
                          {params.value}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography variant="body" component="div">
                        {params.value}
                      </Typography>
                    )}
                  </div>
                )
              }))
            ]}
            getRowId={(params) => params?.session?.id ?? ''}
            getRowClassName={() => classes.tableRow}
            hideFooterPagination
            checkboxSelection
            disableSelectionOnClick
            components={{ Toolbar: () => <TableToolbar /> }}
            onSelectionModelChange={(values) => dispatch(setSelectionModel(values))}
            localeText={getLocalesText(Localize)}
          />
        </TabContainer>
      </TabContent>

      <SessionsDialog
        selectedSessionIds={data.map((el) => el.session.id)}
        eventId={eventId}
        open={isSearchDialogOpen}
        saveSessions={saveSessions}
        onClose={() => dispatch(closeSessionsDialog())}
        disabler={{ confirm: isLoading, cancel: isLoading }}
      />
    </div>
  );
};

export default SessionsTable;
