import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import { get } from 'lodash';

import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';

import { TAB_KEYS } from '@common/Constants';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarItem from '@components/TableToolbarItem';
import { TABLE_OPTIONS } from '@config/network';
import { eventsDetailPath, iltSessionsDetailPath } from '@navigation/routes/Routes';
import {
  selectList,
  fetchHotelBookings,
  selectFilter,
  selectIsLoading,
  resetState,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  selectSelectionModel,
  setSelectionModel
} from '@pages/Locations/components/Tabs/HotelBooking/hotelBookingTableSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  },
  cellCheckbox: {
    padding: '5px 40px 5px 5px !important'
  }
}));

const HotelBookingTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarOptions = [],
  details = null
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);
  const { roles } = details;
  const hotelId = roles[0]?.hotel?.id;

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(fetchHotelBookings({ entityId: hotelId, filter: filter }));
    }
  }, [entityId, hotelId, filter?.page, filter?.sortBy, filter?.sortDirection, filter?.size]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarItemDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add_participant':
        return !entityId || !entityType;
      case 'delete':
        return true; // selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onToolbarItemClick = (fieldName) => {
    switch (fieldName) {
      case 'add_participant':
        return !entityId || !entityType;
      case 'delete':
        return true;
      default:
        return false;
    }
  };

  return (
    <>
      <div className={classes.root}>
        <TabContent>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('IltSession.HotelBooking')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              {toolbarOptions?.map((item, index) => (
                <ToolbarItem
                  key={index}
                  item={item}
                  isDisabled={item.disabled || isToolbarItemDisabled(item.fieldName)}
                  onToolbarItemClick={onToolbarItemClick}
                />
              ))}
            </Toolbar>
          </div>
          <TabContainer>
            <DataGrid
              keepNonExistentRowsSelected={true}
              loading={isLoading}
              rows={data}
              columns={[
                ...columns.map((column) => ({
                  ...column,
                  headerName: Localize.get(column.headerName),
                  renderCell: (params) => {
                    return (
                      <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                        {['participantName', 'companyName', 'roomType'].includes(params.field) ? (
                          <Tooltip title={params.value} arrow>
                            <Typography
                              variant="body"
                              component="div"
                              noWrap
                              sx={{
                                width: 190
                              }}
                            >
                              {params.value}
                            </Typography>
                          </Tooltip>
                        ) : ['hotelName'].includes(params.field) ? (
                          <Tooltip title={params.value} arrow>
                            <Typography
                              noWrap
                              variant="body"
                              component="div"
                              sx={{
                                maxWidth: params?.colDef?.width - 10 // 10 px for three dots,
                              }}
                            >
                              <Link
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                  navigate({
                                    to: params.row.isEvent
                                      ? eventsDetailPath.replace(':eventId', params.row.event.id)
                                      : iltSessionsDetailPath.replace(
                                          ':sessionId',
                                          params.row.event.id
                                        ),
                                    search: { activeTab: TAB_KEYS.OVERVIEW }
                                  });
                                }}
                              >
                                {get(params.row, params.field)}
                              </Link>
                            </Typography>
                          </Tooltip>
                        ) : (
                          get(params.row, params.field)
                        )}
                      </div>
                    );
                  }
                }))
              ]}
              initialState={{
                pagination: {
                  pageSize: totalPages,
                  rowCount: totalElements,
                  page: filter?.page
                }
              }}
              onSelectionModelChange={(newSelectionModel) => {
                dispatch(setSelectionModel(newSelectionModel));
              }}
              selectionModel={selectionModel}
              checkboxSelection
              localeText={getLocalesText(Localize)}
              pagination
              paginationMode="server"
              disableColumnMenu
              page={filter?.page}
              pageSize={filter?.size}
              rowCount={totalElements}
              rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
              disableSelectionOnClick
              components={{ Toolbar: () => <TableToolbar /> }}
              onPageChange={(page) => dispatch(setFilterParams({ page, selectionModel }))}
              onPageSizeChange={(value) =>
                dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
              }
            />
          </TabContainer>
        </TabContent>
      </div>
    </>
  );
};

export default HotelBookingTable;
